<!-- This component is used to display the drawing tools (Pointer, Polyline, Rectangle, Circle, Text, Marker) -->
<template>
  <div class='sl-flex-justify-content-center'>
    <div @click="setDrawingMode('POINTER')"
      class='map_tool_btn'
      :class="{'active-tool-btn':drawingMode==='POINTER'}">
      <pointerIcon style='width: 25px'/>
    </div>
    <div @click="setDrawingMode('POLYLINE')"
      class='map_tool_btn'
      :class="{'active-tool-btn':drawingMode==='POLYLINE'}">
      <lineIcon style='width: 25px'/>
    </div>
    <div @click="setDrawingMode('RECTANGLE')"
      class='map_tool_btn'
      :class="{'active-tool-btn':drawingMode==='RECTANGLE'}">
      <rectangleIcon style='width: 25px'/>
    </div>
    <div @click="setDrawingMode('CIRCLE')"
      class='map_tool_btn'
      :class="{'active-tool-btn':drawingMode==='CIRCLE'}">
      <circleIcon style='width: 25px'/>
    </div>
    <div @click="setDrawingMode('TEXT')"
      class='map_tool_btn'
      :class="{'active-tool-btn':drawingMode==='TEXT'}">
      <textIcon style='width: 25px'/>
    </div>
    <div @click="setDrawingMode('MARKER')"
      class='map_tool_btn'
      :class="{'active-tool-btn':drawingMode==='MARKER'}">
      <markerIcon style='width: 25px'/>
    </div>
  </div>
</template>
<script>
import pointerIcon from '../../../../assets/images/icons/map_pointer.svg';
import markerIcon from '../../../../assets/images/icons/map_marker.svg';
import rectangleIcon from '../../../../assets/images/icons/map_square.svg';
import infoIcon from '../../../../assets/images/icons/map_info_marker.svg';
import circleIcon from '../../../../assets/images/icons/map_circle.svg';
import textIcon from '../../../../assets/images/icons/map_text_icon.svg';
import lineIcon from '../../../../assets/images/icons/map_line.svg';

export default {
  props: {
    drawingMode: {
      type: Object,
      required: true,
    },
  },
  components: {
    pointerIcon,
    markerIcon,
    rectangleIcon,
    infoIcon,
    circleIcon,
    textIcon,
    lineIcon,
  },
  methods: {
    setDrawingMode(mode) {
      this.$emit('set-drawing-mode', mode);
    },
  },
};
</script>
<style scoped>
  .map_tool_btn {
    margin: 3px;
    border: 1px solid #DEDADA;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }
  .map_tool_btn:hover {
    background-color: #FBFBFB;
  }
  .active-tool-btn {
    border: 1px solid #CCDCEB;
    background-color: #CCDCEB !important;
  }
</style>
