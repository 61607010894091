<template>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 750px;'>
      <div class='sl-flex-justify-content-space-btwn sl-border-b-2px-blue sl-pb-10px'>
        <h1 class='sl-text-charcoal sl-h1 sl-font-size-16px sl-w-33per'>
          Property Data Transfer
        </h1>
        <div class='sl-flex-justify-content-end sl-w-33per'>
          <CloseIcon class='figma-icon sl-ml-10px' @click="closeModal"/>
        </div>
      </div>
      <div>
        <div v-if='isUpdating' class='sl-h-126px sl-flex-align-items-center sl-border-b-1px'>
          <div class='sl-w-100px '>
            <div class='sl-spinning-wheel-20px sl-mlr-10px'></div>
          </div>
          <span class='sl-ml-20px sl-font-size-14px'>Updating Property...</span>
        </div>
        <div v-else class='sl-h-126px sl-flex-align-items-center sl-border-b-1px'>
          <button @click='updatePropertyData'
                  :class="{'sl-simple-outline-btn-disabled': isLoading}"
                  class='sl-simple-outline-btn sl-w-100px espm_update_modal_btn'>Update</button>
          <div class='sl-ml-20px sl-font-size-14px'>
            <span v-if='successfulUpdate' class='sl-bold-text sl-partner-blue-text'>
              Utility data successfully updated.
            </span>
            <span v-else-if='errorUpdate' class='sl-bold-text' style='color: #F04C25'>
              Error updating utility data.
            </span>
            <span v-else>
              Update the property with new utilty data from ENERGY STAR<sup>&#174</sup> since the most recent utility data on SiteLynx.
            </span>
          </div>
        </div>
        <div v-if='isSyncing' class='sl-h-126px sl-flex-align-items-center sl-border-b-1px'>
          <div class='sl-w-100px '>
            <div class='sl-spinning-wheel-20px sl-mlr-10px'></div>
          </div>
          <span class='sl-ml-20px sl-font-size-14px'>Syncing Property...</span>
        </div>
        <div v-else class='sl-h-126px sl-flex-align-items-center sl-border-b-1px'>
          <button @click='syncPropertyData'
                  :class="{'sl-simple-outline-btn-disabled': isLoading}"
                  class='sl-simple-outline-btn sl-w-100px espm_update_modal_btn'>Sync</button>
          <div class='sl-ml-20px sl-font-size-14px'>
            <span v-if='successfulSync' class='sl-bold-text sl-partner-blue-text'>
              Utility data successfully synced.
            </span>
            <span v-else-if='errorSync' class='sl-bold-text' style='color: #F04C25'>
              Error syncing utility data.
            </span>
            <span v-else>
              Sync all property data from ENERGY STAR<sup>&#174</sup> from the first bill date to the most recent data available.
              <br>
              <strong>This will overwrite any existing data on SiteLynx.</strong>
            </span>
          </div>
        </div>
        <div class='sl-h-126px sl-flex-align-items-center sl-border-b-1px'>
          <button @click='viewPropertyData'
                  :class="{'sl-simple-outline-btn-disabled': isLoading}"
                  class='sl-simple-outline-btn sl-w-100px espm_update_modal_btn'>View</button>
          <span class='sl-ml-20px sl-font-size-14px'>
            View the property in ENERGY STAR<sup>&#174</sup> portfolio manager.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import CloseIcon from '../../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  components: {
    CloseIcon,
  },
  props: {
    property: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      successfulUpdate: false,
      errorUpdate: false,
      isSyncing: false,
      successfulSync: false,
      errorSync: false,
      syncingMessage: '',
    };
  },
  methods: {
    async updatePropertyData() {
      this.isLoading = true;
      this.isUpdating = true;
      this.successfulUpdate = false;
      this.errorUpdate = false;
      await axios.post('/espm_properties/update_new_property_data',
        {
          property_id: this.property.id,
        })
        .then(() => {
          this.successfulUpdate = true;
          this.isUpdating = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.errorUpdate = true;
          this.isUpdating = false;
          this.isLoading = false;
        });
    },

    async syncPropertyData() {
      this.isLoading = true;
      this.isSyncing = true;
      this.successfulSync = false;
      this.errorSync = false;
      await axios.post('/espm_properties/sync_all_property_data',
        {
          property_id: this.property.id,
        })
        .then(() => {
          this.successfulSync = true;
          this.isSyncing = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.errorSync = true;
          this.isSyncing = false;
          this.isLoading = false;
        });
    },

    closeModal() {
      this.$emit('close-modal');
      this.successfulUpdate = false;
      this.errorUpdate = false;
      this.successfulSync = false;
      this.errorSync = false;
    },

    viewPropertyData() {
      window.open(this.property.espm_url, '_blank');
    },
  },
};
</script>
