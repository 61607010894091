<template>
  <div class='sl-border-t-1px sl-mt-10px sl-pt-20px sl-w-90per sl-flex-justify-content-center sl-plr-5px'>
    <button @click='createDocument()' class='sl-simple-outline-btn sl-w-150px sl-h-28px'>Create Document</button>
  </div>
</template>
<script>
export default {
  methods: {
    createDocument() {
      this.$emit('create-document');
    },
  },
};
</script>
