<template>
  <div class='pl-page-container'>
    <h2 class='sl-static-h2-style' style='line-height:20px'>
    <span v-if='!saved' class='sl-blue-text'>&nbsp*</span><!-- Placeholder to keep spacing on the left -->
    <span>{{ title }}</span>
    <span v-if='!saved'>&nbsp*</span></h2>
  </div>
  <br>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    saved: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
