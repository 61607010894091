<!-- This component is used to display the drawing tools and settings when the map is in full screen mode -->
<template>
  <div id='full_screen_drawing_manager'>
    <div class='sl-flex-justify-align-center sl-mtb-5px sl-ml-5px'>
      <DrawingTools
        :drawingMode='settings.drawingMode'
        @set-drawing-mode='setDrawingMode'
      />
      <div class='sl-mlr-5px'>
        <upArrowIcon v-if='expanded' @click='collapseDrawingManager' class='figma-icon'/>
        <downArrowIcon v-if='!expanded' @click='expandDrawingManager' class='figma-icon'/>
      </div>
    </div>
    <div id='expanded_drawing_manager'>
      <div class='sl-flex-justify-content-space-btwn sl-flex-align-items-center sl-pt-5px'>
        <div class='sl-ml-10px sl-w-110px sl-flex-justify-content-space-btwn'>
          <span @click='showSettings = true' class='menu-toggle' :class="{'menu-toggle-active': showSettings}">Settings</span>
          <span @click='showSettings = false' class='menu-toggle' :class="{'menu-toggle-active': !showSettings}">Drawings</span>
        </div>
        <div class='sl-h-28px sl-mr-10px sl-flex-align-items-center'>
          <span v-if='alertMessage' style='color: #F04C25' class='sl-bold-text'>{{ alertMessage }}</span>
          <div v-else-if='showLoading' class='sl-spinning-wheel-20px sl-mr-10px'></div>
          <span v-else class='menu-toggle-save' @click='$emit("save", {alert: true})'>Save</span>
        </div>
      </div>
      <div class='sl-flex-justify-align-center'>
        <DrawingSettings
          :class="{'sl-hide-content': !showSettings}"
          :settings='settings'
          @set-drawing-setting='setDrawingSetting'
        />
        <MapObjects
          class='map-objects-full-screen'
          :class="{'sl-hide-content': showSettings}"
          :mapObjects='mapObjects'
          :activeIndex='activeIndex'
          @pan-to-location='panToLocation'
          @set-map-object-active='setMapObjectActive'
          @delete-map-object='deleteMapObject'
        />
      </div>
    </div>
  </div>
</template>
<script>
import DrawingTools from './DrawingTools.vue';
import DrawingSettings from './DrawingSettings.vue';
import MapObjects from './MapObjects.vue';
import downArrowIcon from '../../../../assets/images/icons/down_arrow.svg';
import upArrowIcon from '../../../../assets/images/icons/up_arrow.svg';

export default {
  props: {
    settings: {
      type: Object,
      required: true,
    },
    mapObjects: {
      type: Array,
      required: true,
    },
    activeIndex: {
      type: Number,
      required: true,
    },
    fullScreen: {
      type: Boolean,
      required: true,
    },
    alertMessage: {
      type: String,
      required: false,
    },
    showLoading: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    DrawingTools,
    DrawingSettings,
    MapObjects,
    downArrowIcon,
    upArrowIcon,
  },
  watch: {
    fullScreen(newVal) {
      if (newVal) {
        this.mountFullScreenDrawingManager();
      } else {
        this.unmountFullScreenDrawingManager();
      }
    },
  },
  mounted() {
    // Wait for the map to be mounted before adding the drawing manager to the map
    setTimeout(() => {
      const mapContainer = document.getElementById('map').getElementsByTagName('div')[0];
      if (mapContainer) {
        mapContainer.appendChild(this.$el);
      }
    }, 1000);
  },

  data() {
    return {
      zIndex: 200000,
      opacity: 0,
      expanded: false,
      showSettings: true,
      expandedHeight: 0,
      expandedOpacity: 0,
      expandedVisibility: 'hidden',
    };
  },
  methods: {
    mountFullScreenDrawingManager() {
      this.collapseDrawingManager();
      this.showSettings = true;
      this.zIndex = 1000;
      this.opacity = 1;
    },

    unmountFullScreenDrawingManager() {
      this.zIndex = -1;
      this.opacity = 0;
    },
    setDrawingMode(mode) {
      this.$emit('set-drawing-mode', mode);
    },
    setDrawingSetting(setting, value) {
      this.$emit('set-drawing-setting', setting, value);
    },
    expandDrawingManager() {
      this.expanded = true;
      this.expandedHeight = '330px';
      this.expandedOpacity = 1;
      this.expandedVisibility = 'visible';
    },
    collapseDrawingManager() {
      this.expanded = false;
      this.expandedHeight = 0;
      this.expandedOpacity = 0;
      this.expandedVisibility = 'hidden';
    },
    panToLocation(location) {
      this.$emit('pan-to-location', location);
    },
    setMapObjectActive(index) {
      this.$emit('set-map-object-active', index);
    },
    deleteMapObject(index) {
      this.$emit('delete-map-object', index);
    },
  },
};
</script>
<style scoped>
#full_screen_drawing_manager {
  position: absolute;
  top: 0%;
  left: 50%;
  background-color: white;
  width: 260px;
  margin-top: 10px;
  margin-left: -130px;
  z-index: v-bind(zIndex);
  opacity: v-bind(opacity);
  border-radius: 3px;
  transition: opacity 0.3s, z-index 2s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}
#expanded_drawing_manager {
  width: 100%;
  opacity: v-bind(expandedOpacity);
  visibility: v-bind(expandedVisibility);
  height: v-bind(expandedHeight);
  transition: height 0.3s ease-in-out, opacity 0.5s ease-in-out;
}
.menu-toggle {
  cursor: pointer;
  color: var(--medium-dark-charcoal);
  padding: 3px 5px;
  border-radius: 3px;
}
.menu-toggle-active {
  background-color: var(--blue-20) !important;
}
.menu-toggle-save {
  cursor: pointer;
  color: var(--medium-dark-charcoal);
  padding: 3px 8px;
  border-radius: 3px;
  background-color: var(--blue-10) !important;
}
.menu-toggle-save:hover {
  /* color: var(--primary-blue); */
  background-color: var(--blue-40) !important;
}
.map-objects-full-screen {
  margin-top: 15px;
  width: 240px;
  overflow-y: scroll;
  height: 260px;
  border-bottom: 1px solid var(--light-charcoal);
}
</style>
