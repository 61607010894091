<template>
  <div class='sl-border-t-1px sl-w-100per sl-mt-10px'>
    <div class='sl-mt-20px'><!-- Placeholder for spacing --></div>
    <div class='sl-flex-align-items-center'>
      <label for='site_map' class='sl-custom-radio sl-w-60px disabled-font sl-ml-20px'>
        Site Map
        <input @click="toggleMapType('Site Map')" id='site_map' class='map-type-selector' name='map_type' type='radio' :checked="checkMapType('Site Map')"/>
        <span class='checkmark'></span>
      </label>
      <span v-if="checkMapType('Site Plan')" class='sl-tooltip sl-mb-10px'>
        <span @click="copyMap('Site Plan', 'Site Map')" class='sl-bold-text pl_copy_link_btn'>Copy</span>
        <span class='sl-tooltiptext'>Copy Site Map into Site Plan</span>
      </span>
    </div>
    <div class='sl-flex-align-items-center'>
      <label for='site_plan' class='sl-custom-radio sl-w-60px disabled-font sl-ml-20px'>
        Site Plan
        <input @click="toggleMapType('Site Plan')" id='site_plan' class='map-type-selector' name='map_type' type='radio' :checked="checkMapType('Site Plan')"/>
        <span class='checkmark'></span>
      </label>
      <span v-if="checkMapType('Site Map')" class='sl-tooltip sl-mb-10px'>
        <span @click="copyMap('Site Map', 'Site Plan')" class='sl-bold-text pl_copy_link_btn'>Copy</span>
        <span class='sl-tooltiptext'>Copy Site Plan into Site Map</span>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    mapType: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggleMapType(mapType) {
      this.$emit('toggle-map-type', mapType);
    },
    checkMapType(mapType) {
      return this.mapType === mapType;
    },
    copyMap(sourceMapType, targetMapType) {
      this.$emit('copy-map', sourceMapType, targetMapType);
    },
  },
};
</script>
