<template>
  <SpinningModal v-if="showSpinningModal"
    modalLoadingText='Fetching Meter Data...'
  />
  <EspmMeterHeader v-if='!projectSpecific && dataReady'
    :property='property'
    :meter='meter'
    :projectSpecific='projectSpecific'
    @show-modal='showModal = true'
  />
  <CollapsibleSection v-if='dataReady'
    :barText="'Meter Information'"
    :toggleHeaderColor=false
    :bottomBorder=false
    class='sl-mb-20px'>
    <table class='sl-simple-table-1 meter-information'>
      <tbody>
        <tr>
          <td>Meter Name</td>
          <td>{{ meter.name }}</td>
        </tr>
        <tr>
          <td>Meter Type</td>
          <td>{{ meter.meter_type }}</td>
        </tr>
        <tr>
          <td>Energy Star ID</td>
          <td>{{ meter.espm_api_meter_id }}</td>
        </tr>
        <tr>
          <td>Unit of Measure</td>
          <td>{{ meter.unit_of_measure }}</td>
        </tr>
        <tr>
          <td>First Bill Date</td>
          <td>{{ meter.first_bill_date }}</td>
        </tr>
        <tr>
          <td>Most Recent Bill Date</td>
          <td>{{ meter.most_recent_bill_date }}</td>
        </tr>
        <tr>
          <td>Metered</td>
          <td>{{ meter.bool_metered }}</td>
        </tr>
        <tr>
          <td>In Use</td>
          <td>{{ meter.bool_in_use }}</td>
        </tr>
      </tbody>
    </table>
    <br>
  </CollapsibleSection>

  <CollapsibleSection v-if='dataReady'
    :barText="returnUtilityDataHeader(meter)"
    :toggleHeaderColor=false
    :bottomBorder=false
    class='sl-mb-20px'>
    <table v-if='utilityDataAvailable' class='sl-simple-table-1 utility-data'>
      <thead>
        <tr class='sl-border-t-0 sl-sticky-header'>
          <td class='sl-w-200px sl-bold-text'><div class='sticky-header-container'>Start Date</div></td>
          <td class='sl-w-200px sl-bold-text'><div class='sticky-header-container'>End Date</div></td>
          <td class='sl-w-200px sl-bold-text'><div class='sticky-header-container'>Usage</div></td>
          <td class='sl-w-200px sl-bold-text'><div class='sticky-header-container'>Cost</div></td>
          <td>
            <div class='sticky-header-container paginate-header'>
              <Paginate v-if='numberOfPages && currentPage'
                style='padding: 0 10px; border-bottom: 0;'
                :numberOfPages='numberOfPages'
                :currentPage='currentPage'
                :align="'right'"
                :margin="'0'"
                @change-page='changePage'
              />
              <div class='sl-w-30px'>
                <div v-if='showSpinningWheel' class='sl-spinning-wheel-20px' style='height: 20px'></div>
              </div>
            </div>
          </td>
        </tr>
      </thead>
      <tbody class='utility-data-tbody' :class="{ 'utility-data-tbody-loading': fetchingUtilityData }">
        <tr v-for='data in utilityData' :key='data.id'>
          <td>{{ data.start_date }}</td>
          <td>{{ data.end_date }}</td>
          <td>{{ data.usage }}</td>
          <td>{{ data.cost }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div v-else class='sl-font-size-14px sl-h-200px sl-border-b-2px
                      sl-flex-justify-content-and-align-items-center'>
      There is no utility data available for this meter.
    </div>
    <br>
  </CollapsibleSection>
  <EspmMeterDataTransferModal
    v-if='showModal'
    :meter='meter'
    :property='property'
    @close-modal='closeModal'
  />
</template>
<script>
import axios from 'axios';
import EspmMeterHeader from './EspmMeterHeader.vue';
import EspmMeterDataTransferModal from './EspmMeterDataTransferModal.vue';
import CollapsibleSection from '../../../components/CollapsibleSection.vue';
import SpinningModal from '../../../components/SpinningModal.vue';
import Paginate from '../../../components/Paginate.vue';

export default {
  components: {
    EspmMeterHeader,
    EspmMeterDataTransferModal,
    CollapsibleSection,
    Paginate,
    SpinningModal,
  },

  mounted() {
    this.setMeterId();
    this.setPage();
    this.fetchMeter();
    this.modalBtnListener();
  },

  data() {
    return {
      meterId: null,
      projectId: null,
      projectScopeId: null,
      meter: null,
      property: null,
      utilityData: [],
      currentPage: 1,
      numberOfPages: null,
      showSpinningModal: false,
      showSpinningWheel: false,
      fetchingUtilityData: false,
      dataReady: false,
      showModal: false,
      projectSpecific: false,
      utilityDataHeaderTop: null,
      utilityDataAvailable: true,
    };
  },

  methods: {
    // Set attibutes based on if rendering a project specific meter page or standard meter page
    setMeterId() {
      if (window.location.pathname.includes('project_specific')) {
        const urlParams = new URLSearchParams(window.location.search);
        this.meterId = urlParams.get('id');
        this.currentPage = urlParams.get('page');
        this.projectId = urlParams.get('project_id');
        this.projectScopeId = urlParams.get('project_scope_id');
        this.projectSpecific = true;
        this.utilityDataHeaderTop = '35px';
      } else {
        this.meterId = window.location.pathname.split('/').pop();
        this.utilityDataHeaderTop = '44px';
      }
    },

    // Set the page number from the URL params
    setPage() {
      const urlParams = new URLSearchParams(window.location.search);
      this.currentPage = urlParams.get('page');
    },

    // Fetch the meter data and utility data
    async fetchMeter() {
      this.showSpinningModal = true;
      axios.get('/espm_meters/return_meter_json', {
        params: {
          id: this.meterId,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.property = response.data.property;
          this.meter = response.data.meter;
          this.utilityData = response.data.utility_data;
          this.numberOfPages = response.data.number_of_pages;
          this.utilityDataAvailable = response.data.utility_data_available;
          setTimeout(() => {
            this.dataReady = true;
            this.showSpinningModal = false;
          }, 300);
        });
    },

    // Only fetch the utility data
    async fetchUtilityData() {
      this.showSpinningWheel = true;
      this.fetchingUtilityData = true;
      setTimeout(() => {
        axios.get('/espm_consumption_data/return_utility_data_json', {
          params: {
            meter_id: this.meterId,
            page: this.currentPage,
          },
        })
          .then((response) => {
            this.utilityData = response.data.utility_data;
            this.fetchingUtilityData = false;
            this.showSpinningWheel = false;
          });
      }, 300);
    },

    // Event listener for the data transfer icon, used for project specific meter pages
    modalBtnListener() {
      const modalBtn = document.getElementById('sl-espm-data-transfer-icon');
      if (this.projectSpecific && modalBtn) {
        modalBtn.addEventListener('click', () => {
          this.showModal = true;
        });
      }
    },

    // Return the text for the utility data header
    returnUtilityDataHeader(meter) {
      if (meter.unit_of_measure) {
        return `Utility Data — ${meter.unit_of_measure}`;
      }
      return 'Utility Data';
    },

    // Change page number and fetch the utility data
    changePage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchUtilityData();
      const params = new URLSearchParams();
      if (this.projectSpecific) {
        params.set('id', this.meterId);
        params.set('page', pageNumber);
        params.set('project_id', this.projectId);
        params.set('project_scope_id', this.projectScopeId);
      } else {
        params.set('page', pageNumber);
      }
      history.replaceState(null, null, `?${params.toString()}`); // eslint-disable-line no-restricted-globals
    },

    // Close the data transfer modal and reload data
    closeModal() {
      this.showModal = false;
      this.fetchMeter();
    },
  },
};
</script>
<style scoped>
  .meter-information,
  .utility-data {
    font-size: 14px;
    border-top: 0;
    border-bottom: 2px solid var(--medium-charcoal);
  }

  .meter-information td:first-child {
    color: #696E76;
    width: 275px;
  }

  .utility-data td:nth-child(1),
  .utility-data td:nth-child(2) {
    color: #696E76;
  }

  tr {
    height: 42px;
    text-align: left;
  }

  .utility-data thead tr {
    top: v-bind('utilityDataHeaderTop');
    background-color: #fff;
    border-bottom: 0
  }

  .sticky-header-container {
    border-bottom: 2px solid var(--primary-blue);
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 5px;
  }

  .utility-data thead td {
    padding: 0 !important;
  }

  .utility-data-tbody {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  .utility-data-tbody-loading {
    opacity: 0.3;
  }

  .paginate-header {
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }
</style>
