<template>
  <form @submit.prevent="importFile()"
        class='sl-mb-20px sl-pb-20px sl-border-b-1px sl-h-60px'
        :class="{'disabled-form': disableForm()}">
    <label class='sl-flex-column'>
      <span class='sl-font-size-14px sl-bold-text' style='color: #696E76'>{{ displayName }}</span>
      <span class='disabled-font '>Count: {{ count }}</span>
    </label>
    <div class='sl-flex-align-items-center sl-flex-justify-content-space-btwn sl-mt-5px sl-h-28px'>
      <input type='file'
             :id='`${fileType}_file`'
             required
             accept='.csv'
             ref='file'
             :disabled='importing || policiesNotImported() || versionNotCreated()'>
      <div v-if="importing === fileType" class='sl-flex-only sl-align-items-center'>
        <span>Importing {{ displayName }}...</span>
        <div class='sl-spinning-wheel-20px sl-mlr-10px'></div>
      </div>
      <span v-else-if='versionNotCreated()' class='disabled-font sl-bold-text'>Create Policy Version First</span>
      <span v-else-if='policiesNotImported()' class='disabled-font sl-bold-text'>Import Policies First</span>
      <button v-else type='submit' class='sl-w-85px sl-simple-outline-btn'
              :class="{'sl-simple-outline-btn-disabled': disableButton()}">
        Import
      </button>
    </div>
  </form>
</template>
<script>
export default {
  props: {
    policyVersion: {
      type: Object,
      required: true,
    },
    importing: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    displayName: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },

  methods: {
    importFile() {
      this.$emit('importFile', this.fileType, this.displayName, this.$refs.file.files[0]);
    },

    disableButton() {
      if (!this.policyVersion.id || this.importing) {
        return true;
      }
      return false;
    },

    versionNotCreated() {
      return !this.policyVersion.id;
    },

    policiesNotImported() {
      if (this.fileType === 'zip_codes' || this.fileType === 'property_types') {
        return parseInt(this.policyVersion.policies, 10) === 0;
      }
      return false;
    },

    disableForm() {
      return this.versionNotCreated() || this.policiesNotImported();
    },
  },
};
</script>
<style scoped>
.disabled-form {
  opacity: 0.7;
}
</style>
