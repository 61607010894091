<template>
  <div v-if='documentId' class='sl-flex-align-items-center sl-mt-10px'>
    <a :href=documentLink() class='sl-tooltip'>
      <pdfIcon class='sl-mr-10px figma-icon'/>
      <span class='sl-tooltiptext'>Download {{ documentType }}</span>
    </a>
    <div>
      <div class='sl-bold-text' style='color: #333E47'>{{ figure }}</div>
      <div class='disabled-font'>{{ documentType }}</div>
    </div>
  </div>
  <div v-else class='sl-flex-align-items-center'>
    <div class='sl-tooltip'>
      <pdfIconDisabled class='sl-mr-10px'/>
      <span class='sl-tooltiptext'>{{ documentType }} Not Created</span>
    </div>
    <div>
      <div class='sl-bold-text disabled-font'>{{ figure }}</div>
      <div class='disabled-font'>{{ documentType }}</div>
    </div>
  </div>
</template>
<script>
import pdfIcon from '../../../../assets/images/icons/Icon-PDF-Doc_Charcoal-Dark-100_24.svg';
import pdfIconDisabled from '../../../../assets/images/icons/Icon-PDF-Doc_Charcoal-Medium-100_24.svg';

export default {
  components: {
    pdfIcon,
    pdfIconDisabled,
  },
  props: {
    documentId: {
      type: String,
      required: true,
    },
    documentType: {
      type: String,
      required: true,
    },
    documentLink: {
      type: String,
      required: true,
    },
    figure: {
      type: String,
      required: true,
    },
  },
};
</script>
