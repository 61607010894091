<template>
  <br>
  <SpinningModal v-if='showLoadingModal'/>
  <ReportTableFields v-if='dataReady'
    :field='reportField'
    :tableName='tableName'
    :isFieldPresent='!isObjectEmpty(reportField)'
    @update='updateReportField'
    @update-requirements-code='updateRequirementsCode'
    @import-table='importTable'
  />
  <div v-if='dataReady'>
    <div :class='alignmentClass()'>
      <div>
        <div class='sl-flex-justify-content-space-btwn sl-ptb-10px' id='report-table-header'>
          <div class='sl-min-w-200px sl-flex-align-items-center'>
            <span title='Save'>
              <saveIcon @click='update()' class='sl-cursor-pointer figma-icon-link sl-ml-5px'/>
            </span>
            <span title='Return To Section'>
              <returnIcon @click='cancel()' class='sl-cursor-pointer figma-icon-link sl-ml-5px'/>
            </span>
            <button @click='create()' class='sl-simple-outline-btn sl-ml-5px sl-mr-10px'>Add Rows</button>
            <input type='number' min='1' :max='maxRowsAvailable()' v-model='rowsToAdd'
                   class='sl-border-1px-medium-charcoal sl-w-60px sl-pl-5px sl-ptb-5px'
                   id='row_number'/>
            <div class='sl-bold-text sl-font-size-14px sl-ml-10px'>
              <span style='color: #F04C25'>{{ alertMessage }}</span>
            </div>
          </div>
          <span title='Delete All'>
            <deleteIcon @click='deleteAll()' class='sl-cursor-pointer figma-icon-link sl-ml-5px' transform='scale(0.8)'/>
          </span>
        </div>
        <h2 class='sl-static-h2-style sl-text-align-left' id='report-table-title'>
          <span class='sl-ml-10px'>{{ title }}</span>
          <span class='sl-ml-5px sl-cursor-pointer' title='There are unsaved changes' v-if='!tableSaved'>*</span>
        </h2>
        <div id='sc-td-height-vue'>
          <table @input='initTableChanged()' border='1' bordercolor='#E0E0E0' cellpadding='1' cellspacing='1'>
            <thead class='sl-h-32px'>
              <tr>
                <th class='sl-min-w-30px report-table-header-cell'></th>
                <th v-if='sortable' class='sl-min-w-30px report-table-header-cell'></th>
                <th class='sl-min-w-30px report-table-header-cell'></th>
                <th v-for='header in tableHeaders' class='report-table-header-cell sl-plr-10px'>{{ header }}</th>
                <th v-if='extraInfoRowAvailable' class='report-table-header-cell'></th>
              </tr>
            </thead>
            <tbody id='pl_table_body'>
              <tr v-for='(row, rowIndex) in tableData' valign='top' :id=row.id>
                <td class='sl-text-align-center sl-pt-10px sl-bold-text' style='color: #333E47'>{{ row.sort_order + 1 }}.</td>
                <td v-if='sortable' class='sl-cursor-pointer sortable-handle'>
                  <handle @mousedown='hideAllExtraInfo'/>
                </td>
                <td class='sl-text-align-center sl-pt-5px'>
                    <deleteIcon @click='deleteRow(row, rowIndex)'
                                class='figma-icon-delete links-center sl-mt-3px'
                                transform='scale(0.7)'/>
                </td>
                <td class='sl-w-100px' v-for='(column, columnIndex) in columns'>
                  <!-- Textarea-->
                  <div v-if="columnTypes[columnIndex] === 'textarea'">
                    <textarea v-model='row[column]'
                              @input='autoFitTextAreaOnInput'
                              :id='`${row.id}_${column}`'
                              :disabled='isDisabledCell(rowIndex, column)'
                              rows='2' :cols='columnWidths[columnIndex]'>
                    </textarea>
                  </div>
                </td>
                <td v-if='extraInfoRowAvailable'>
                  <button @click='toggleExtraInfo(row)'
                          class='sl-simple-outline-btn sl-mlr-5px sl-mt-5px'
                          :class="{ 'sl-simple-btn-active':row.extra_info }">
                    Extra Info
                  </button>
                </td>
              </tr>
              <tr v-if='extraInfoRowAvailable' class='sl-hide-content extra-info-row'
                  v-for='row in tableData' valign='top' :id='`${row.id}_extra_info`'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td :colspan='columns.length' class='sl-p-all-5px'>
                    <textarea v-model='row[extraInfoColumn]' class='extra-info-textarea' style='width: 99%' rows='3'></textarea>
                  </td>
                  <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ReportTableInstructions
        v-if='instructionsAvailable'
        :tableName='tableName'
        :dataItems='dataItems'
      />
    </div>
  </div>
</template>
<script>
/* eslint max-lines: off */
/* eslint complexity: off */
import axios from 'axios';
import Sortable from 'sortablejs';
import ReportTableFields from './ZoningReportTableFields.vue';
import ReportTableInstructions from './ZoningReportTableInstructions.vue';
import SpinningModal from '../../../components/SpinningModal.vue';
import handle from '../../../../../assets/images/icons/handle.svg';
import deleteIcon from '../../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';
import saveIcon from '../../../../../assets/images/icons/save_icon.svg';
import returnIcon from '../../../../../assets/images/icons/return_icon.svg';

export default {
  components: {
    SpinningModal,
    ReportTableFields,
    ReportTableInstructions,
    handle,
    deleteIcon,
    saveIcon,
    returnIcon,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      projectId: urlParams.get('project_id'),
      projectScopeId: urlParams.get('project_scope_id'),
      tableName: urlParams.get('the_group'),
      title: '',
      constantTitle: '',
      tableHeaders: [],
      columns: [],
      columnTypes: [],
      columnWidths: [],
      tableData: [],
      lockedRequirements: {},
      reportField: {},
      dataItems: [],
      sortable: false,
      maxRows: null,
      rowsToAdd: 1,
      showLoadingModal: false,
      alertMessage: '',
      alignment: 'left',
      instructionsAvailable: false,
      extraInfoRowAvailable: false,
      extraInfoColumn: null,
      section: urlParams.get('section') || 1,
      user: null,
      dataReady: false,
      tableSaved: true,
    };
  },

  mounted() {
    this.fetchTableData();
    this.setPageExitPrompt();
  },

  methods: {
    async fetchTableData() {
      axios.get('/zoning_report_tables/return_table_data', {
        params: {
          project_id: this.projectId,
          project_scope_id: this.projectScopeId,
          table_name: this.tableName,
        },
      })
        .then((response) => {
          this.tableHeaders = response.data.report_table.constants.table_headers;
          this.title = response.data.report_table.constants.title;
          this.constantTitle = response.data.report_table.constants.title;
          this.columns = response.data.report_table.constants.columns;
          this.columnTypes = response.data.report_table.constants.column_types;
          this.columnWidths = response.data.report_table.constants.column_widths;
          this.maxRows = response.data.report_table.constants.max_rows;
          this.sortable = response.data.report_table.constants.sortable;
          this.tableData = response.data.report_table.table_data;
          this.reportField = response.data.report_field || {};
          this.dataItems = response.data.data_items || [];
          this.lockedRequirements = response.data.locked_requirements;
          this.alignment = response.data.report_table.constants.alignment;
          this.instructionsAvailable = response.data.report_table.constants.instructions_available;
          this.extraInfoRowAvailable = response.data.report_table.constants.extra_info_row_available;
          this.extraInfoColumn = response.data.report_table.constants.extra_info_column;
          this.user = response.data.user;
          this.setTableDataFromField();
          // Delay to allow the table to render before adjusting the textareas
          setTimeout(() => { this.autoShowExtraInfo(); }, 100);
          setTimeout(() => {
            this.autoFitTextAreasOnPageLoad();
            if (this.sortable) { this.setSortable(); }
          }, 500);
        })
        .catch(() => {
          this.alertMessage = 'Error loading table data';
        })
        .finally(() => {
          this.dataReady = true;
        });
    },

    // Add rows to the table
    async create() {
      if ((this.tableData.length + this.rowsToAdd) > this.maxRows) {
        alert(`The maximum number of rows allowed is ${this.maxRows}.`);
        return;
      }
      axios.post('/zoning_report_tables/create_new_rows', null, {
        params: {
          project_scope_id: this.projectScopeId,
          table_name: this.tableName,
          rows: this.rowsToAdd,
        },
      })
        .then((response) => {
          this.tableData = this.tableData.concat(response.data.new_rows);
          this.rowsToAdd = 1;
          this.initTableChanged();
        })
        .catch(() => {
          this.alertMessage = 'Error creating rows';
        });
    },

    // Save the table data
    async update() {
      this.showLoadingModal = true;
      axios.put('/zoning_report_tables/update_table', {
        project_scope_id: this.projectScopeId,
        table_name: this.tableName,
        table_data: this.tableData,
      })
        .then(() => {
          setTimeout(() => { this.showLoadingModal = false; }, 200);
          this.alertMessage = 'Table Saved';
          this.tableSaved = true;
        })
        .catch(() => {
          this.showLoadingModal = false;
          this.alertMessage = 'Error saving table';
        });
    },

    // Delete a row from the table
    async deleteRow(row, rowIndex) {
      if (this.isObjectEmpty(row) || window.confirm('Are you sure you want to delete this row?')) {
        await axios.post('/zoning_report_tables/destroy_row', {
          id: row.id,
          project_scope_id: this.projectScopeId,
          table_name: this.tableName,
          table_data: this.tableData,
        })
          .then(() => {
            this.tableData.splice(rowIndex, 1);
            this.updateSortOrder();
            this.initTableChanged();
          })
          .catch(() => {
            this.alertMessage = 'Error deleting row';
          });
      }
    },

    // Delete all rows in the table
    async deleteAll() {
      if (!window.confirm('Are you sure you want to delete all rows?')) {
        return;
      }
      await axios.post('/zoning_report_tables/destroy_all_rows', {
        project_scope_id: this.projectScopeId,
        table_name: this.tableName,
      })
        .then(() => {
          this.tableData = [];
          this.initTableChanged();
        })
        .catch(() => {
          this.alertMessage = 'Error deleting all rows';
        });
    },

    // Update optional field above the report table,
    // Currently only one textarea field is used to set the table title or proposed/existing header
    async updateReportField(fieldData) {
      this.reportField.data = fieldData;
      await axios.put('/zoning_report_tables/update_report_table_field', {
        project_id: this.projectId,
        project_scope_id: this.projectScopeId,
        field_name: this.reportField.name,
        data: fieldData,
      })
        .then(() => {
          this.setTableDataFromField();
        })
        .catch(() => {
          this.alertMeassage = 'Error updating report field';
        });
    },

    // Return back to the writer/preview page
    cancel() {
      const message = 'Return to main page? You will lose any unsaved changes.';
      // If the table has not changed, return to section; if the table has changed, show the confirm dialog
      if (this.tableSaved || window.confirm(message)) {
        const url = `/zoning_report_tables/group_cancel?project_id=${this.projectId}`
          + `&project_scope_id=${this.projectScopeId}&table_section=${this.section}`;
        window.location.href = url;
      }
    },

    // Set up the sortable table rows
    setSortable() {
      const el = document.getElementById('pl_table_body');
      Sortable.create(el, {
        handle: '.sortable-handle',
        animation: 150,
        ghostClass: 'sortable-ghost',
        onEnd: () => {
          this.updateSortOrder();
          this.initTableChanged();
        },
      });
    },

    // Loop through elements in the table and update the sort_order field
    updateSortOrder() {
      const el = document.getElementById('pl_table_body');
      let index = 0;
      for (let row of el.children) { // eslint-disable-line no-restricted-syntax, prefer-const
        const htmlRow = this.tableData.find((r) => r.id == row.id); // eslint-disable-line eqeqeq
        if (htmlRow) {
          htmlRow.sort_order = index;
          index++;
        }
      }
    },

    // Return the number of rows that can be added to the table
    maxRowsAvailable() {
      return this.maxRows - this.tableData?.length;
    },

    // Return flex alignment class based on alignment prop
    alignmentClass() {
      let alignmentClass = 'sl-flex-justify-content-start';
      if (this.alignment === 'center') {
        alignmentClass = 'sl-flex-justify-content-center';
      } else if (this.alignment === 'right') {
        alignmentClass = 'sl-flex-justify-content-end';
      }
      return alignmentClass;
    },

    // Set the table title or existing/proposed header from the report field data
    setTableDataFromField() {
      if (this.tableName.includes('use conformance') || this.tableName.includes('parking requirements')) {
        if (this.reportField.data === '' || this.reportField.data === null) {
          this.title = this.constantTitle;
        } else {
          this.title = this.reportField.data;
        }
      } else if (this.tableName.includes('bulk requirements')
        || this.tableName.includes('mixed use')
        || this.tableName.includes('optional requirements')
        || this.tableName.includes('split requirements')) {
        if (this.reportField.data === '' || this.reportField.data === null) {
          this.tableHeaders[3] = 'Existing';
        } else {
          this.tableHeaders[3] = this.reportField.data;
        }
      }
    },

    // Expand text areas to fit their content on page load
    autoFitTextAreasOnPageLoad() {
      const textareas = document.querySelectorAll('textarea');
      textareas.forEach((textarea) => {
        if (!textarea.classList.contains('extra-info-textarea')) {
          const el = textarea;
          el.style.height = 'auto';
          el.style.height = `${textarea.scrollHeight}px`;
        }
      });
    },

    // Expand a textarea to its content as data is input
    autoFitTextAreaOnInput(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },

    // Return true if the cell is disabled, disabled cells are visibile but not editable
    isDisabledCell(rowIndex, column) {
      if ((this.tableName.includes('bulk requirements')
           || this.tableName.includes('mixed use')
           || this.tableName.includes('split requirements'))
           && column === 'b_column') {
        const cellData = this.tableData[rowIndex][column];
        return this.lockedRequirements[cellData];
      }
      return false;
    },

    // Return true if the table row is empty
    isObjectEmpty(row) {
      let empty = true;
      const ignoreColumns = ['id', 'project_id', 'sort_order', 'created_at', 'deprecated', 'name', 'updated_at'];
      for (let i = 0; i < Object.keys(row).length; i++) {
        const key = Object.keys(row)[i];
        const value = row[Object.keys(row)[i]];
        // If the column is not in the ignore list and the value is not null or empty, the row is not empty
        if (!ignoreColumns.includes(key) && value !== null && value !== '') {
          empty = false;
          break;
        }
      }
      return empty;
    },

    // Toggle the extra info row for a table row
    toggleExtraInfo(row) {
      const extraInfoRow = document.getElementById(`${row.id}_extra_info`);
      if (extraInfoRow.classList.contains('sl-hide-content')) {
        this.showExtraInfo(row);
      } else {
        this.hideExtraInfo(row);
      }
    },

    // Show the extra info row for a table row, the extra info row html element is appended to the row it belongs to
    showExtraInfo(row) {
      const tableBody = document.getElementById('pl_table_body');
      const htmlRows = tableBody.children;
      for (let i = 0; i < htmlRows.length; i++) {
        const htmlRowId = parseInt(htmlRows[i].id, 10);
        const dataRowId = parseInt(row.id, 10);
        if (htmlRowId === dataRowId) {
          const htmlRow = document.getElementById(row.id);
          const extraInfoRow = document.getElementById(`${row.id}_extra_info`);
          htmlRow.after(extraInfoRow);
          extraInfoRow.classList.remove('sl-hide-content');
        }
      }
    },

    // Hide the extra info row for a table row
    hideExtraInfo(row) {
      const extraInfoRow = document.getElementById(`${row.id}_extra_info`);
      extraInfoRow.classList.add('sl-hide-content');
      const tableBody = document.getElementById('pl_table_body');
      tableBody.appendChild(extraInfoRow);
    },

    // Hide all extra info rows, called when sorting rows to prevent extra info rows from being misplaced
    hideAllExtraInfo() {
      const extraInfoRows = document.querySelectorAll('.extra-info-row');
      extraInfoRows.forEach((row) => {
        if (!row.classList.contains('sl-hide-content')) {
          row.classList.add('sl-hide-content');
        }
      });
    },

    // Show extra info rows on page load if there is data in them
    autoShowExtraInfo() {
      if (this.extraInfoRowAvailable) {
        this.tableData.forEach((row) => {
          if (row[this.extraInfoColumn] !== null && row[this.extraInfoColumn] !== '') {
            this.showExtraInfo(row);
          }
        });
      }
    },

    // Update the requirements code for all rows in the table
    updateRequirementsCode(code) {
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].c_column = code;
      }
    },

    // Import table data from another table
    async importTable(importTableName) {
      this.showLoadingModal = true;
      axios.post('/zoning_report_tables/import_table', {
        project_scope_id: this.projectScopeId,
        table_name: this.tableName,
        import_table_name: importTableName,
      })
        .then((response) => {
          this.tableData = response.data.table_data;
          this.showLoadingModal = false;
          this.alertMessage = 'Table Imported';
          setTimeout(() => { this.autoShowExtraInfo(); }, 200);
          setTimeout(() => { this.alertMessage = ''; }, 2500);
        })
        .catch(() => {
          this.alertMessage = 'Error importing table';
          this.showLoadingModal = false;
        });
    },

    initTableChanged() {
      this.tableSaved = false;
      this.alertMessage = '';
    },

    // If the table has unsaved changes, show a prompt when the user tries to leave the page
    setPageExitPrompt() {
      window.onbeforeunload = () => {
        if (!this.tableSaved) {
          return 'You have unsaved changes. Are you sure you want to leave this page?';
        }
        return null;
      };
    },
  },
};
</script>
<style scoped>
  .sl-simple-btn-active {
    background-color: #005199;
    color: white !important;
  }
</style>
