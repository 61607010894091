<template>
  <div class='sl-border-t-1px sl-mt-10px sl-pt-10px sl-flex-justify-content-center'>
    <button @click='save()'
            class='sl-simple-outline-btn sl-w-85px sl-mt-5px'>
            Save
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    save() {
      this.$emit('save', { alert: true });
    },
  },
};
</script>
