<template>
  <div v-if='locationIsSet' id='map' class='map-container-portrait' :class="{'map-container-landscape':!aspect}"></div>
  <div v-else class='map-container-portrait sl-text-align-center' style='background-color: #F5F5F5;'>
    <div v-if='dataReady' class='sl-font-size-14px sl-mt-60px'>The property location has not been set.</div>
  </div>
</template>
<script>
export default {
  props: {
    locationIsSet: {
      type: Boolean,
      required: true,
    },
    dataReady: {
      type: Boolean,
      required: true,
    },
    aspect: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
<style scoped>
.map-container-portrait {
  width: 600px;
  height: 790px;
}
.map-container-landscape {
  min-width: 760px !important;
  width: 760px !important;
  height: 405px;
}
</style>
