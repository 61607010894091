<template>
  <CollapsibleSection :barText="'Meters'"
                      :toggleHeaderColor=false
                      :bottomBorder=false
                      class='sl-mb-20px'>
    <div v-if='metersAvailable'>
      <table v-if='meters.length' class='sl-simple-table-1 sl-border-b-2px sl-font-size-14px'>
        <thead>
          <tr class='sl-h-42px sl-bold-text sl-text-align-left sl-h-37px sl-border-t-0'>
            <td class='sl-w-25per sl-pl-10px'>Name</td>
            <td class='sl-w-25per sl-pl-5px'>Most Recent Usage</td>
            <td class='sl-w-25per'>Most Recent Cost</td>
            <td class='sl-w-25per'>Most Recent Bill Date</td>
          </tr>
        </thead>
        <tbody class='sl-text-align-left'>
          <tr v-for='meter in meters' :key='meter.id' class='sl-h-42px'>
            <td class='sl-pl-10px'>
              <a v-if='meter.name' :href="meter.url">{{ meter.name }}</a>
              <a v-else :href="meter.url" style='color: #9F9EA4;'>Meter Name Missing</a>
            </td>
            <td>{{ meter.most_recent_usage }}</td>
            <td>{{ meter.most_recent_cost }}</td>
            <td>{{ meter.most_recent_bill_date }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class='sl-font-size-14px sl-h-126px sl-border-b-2px
                      sl-flex-justify-content-and-align-items-center'>
      There are no meters available for this property.
    </div>
    <br>
  </CollapsibleSection>
</template>
<script>
import axios from 'axios';
import CollapsibleSection from '../../../components/CollapsibleSection.vue';

export default {
  components: {
    CollapsibleSection,
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: false,
    },
    projectScopeId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      meters: [],
      fetchingMeters: false,
      metersAvailable: true,
    };
  },
  mounted() {
    this.fetchMeters();
  },
  methods: {
    fetchMeters() {
      this.fetchingMeters = true;
      axios.get('/espm_meters/return_meters_json', {
        params: {
          property_id: this.propertyId,
          project_id: this.projectId,
          project_scope_id: this.projectScopeId,
        },
      })
        .then((response) => {
          this.meters = response.data.meters;
          this.metersAvailable = this.meters.length > 0;
        })
        .catch(() => {
          this.$emit('error-fetching-meters');
        })
        .finally(() => {
          this.$emit('meter-data-ready');
          setTimeout(() => {
            this.fetchingMeters = false;
          }, 500);
        });
    },
  },
};
</script>
