<template>
  <div class='sl-flex-justify-content-center sl-h-15px sl-bold-text sl-font-size-14px' style='color: #F04C25'>
    {{ message }}
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
