<template>
  <!-- There are conditionals to disable some of the drawing settings depending on the selected tool -->
  <div v-if="['POINTER', 'MARKER'].includes(settings.drawingMode)" class='sl-w-245px sl-mt-10px'>
    <div class='disabled-setting'>
      <div class='color-label'>Stroke Color</div>
      <div class='sl-flex-only sl-mb-10px sl-ml-10px'>
        <ColorButton v-for='color in colors'
          :color='color'
          :current_color='strokeColorSetting'
          :disabled='true'
        />
      </div>
    </div>
    <div class='disabled-setting'>
      <div class='color-label'>Fill Color</div>
      <div class='sl-flex-only sl-mb-20px sl-ml-10px'>
        <ColorButton v-for='color in colors'
          :color='color'
          :current_color='fillColorSetting'
          :disabled='true'
        />
      </div>
    </div>
    <div class='sl-border-t-1px sl-h-150px sl-flex-align-items-center sl-w-100per'>
      <div class='disabled-setting sl-flex-column sl-flex-align-items-center sl-w-100per'>
        <label class='slider-label'>
          <span>Stroke Weight</span>
          <span>({{ strokeWeightSetting }})</span>
        </label>
        <input v-model='strokeWeightSetting' disabled
               type='range' step='1' min='0' max='10' class='slider' id='stroke_weight'/>

        <label class='slider-label'>
          <span>Stroke Opacity</span>
          <span>({{ strokeOpacitySetting * 10 }})</span>
        </label>
        <input v-model='strokeOpacitySetting' disabled
                type='range' step='0.1' min='0' max='1' class='slider' id='stroke_opacity'/>

        <label class='slider-label'>
          <span>Fill Opacity</span>
          <span>({{ fillOpacitySetting * 10 }})</span>
        </label>
        <input v-model='fillOpacitySetting' disabled
                type='range' step='0.1' min='0' max='1' class='slider' id='fill_opacity'/>
      </div>
    </div>
  </div>
  <div v-else-if="settings.drawingMode==='POLYLINE'" class='sl-w-245px sl-mt-10px'>
    <div>
      <div class='color-label'>Stroke Color</div>
      <div class='sl-flex-only sl-mb-10px sl-ml-10px'>
        <ColorButton v-for='color in colors'
          :color='color'
          :current_color='strokeColorSetting'
          @set-color='setStrokeColor'
        />
      </div>
    </div>
    <div class='disabled-setting'>
      <div class='color-label'>Fill Color</div>
      <div class='sl-flex-only sl-mb-20px sl-ml-10px'>
        <ColorButton v-for='color in colors'
          :color='color'
          :current_color='fillColorSetting'
          :disabled='true'
        />
      </div>
    </div>
    <div class='sl-border-t-1px sl-h-150px sl-flex-align-items-center sl-w-100per'>
      <div class='sl-flex-column sl-flex-align-items-center sl-w-100per'>
        <label class='slider-label'>
          <span>Stroke Weight</span>
          <span>({{ strokeWeightSetting }})</span>
        </label>
        <input v-model='strokeWeightSetting' @input='setStrokeWeight'
               type='range' step='1' min='0' max='10' class='slider' id='stroke_weight'/>

        <label class='slider-label'>
          <span>Stroke Opacity</span>
          <span>({{ strokeOpacitySetting * 10 }})</span>
        </label>
        <input v-model='strokeOpacitySetting' @input='setStrokeOpacity'
               type='range' step='0.1' min='0' max='1' class='slider' id='stroke_opacity'/>

        <label class='slider-label'>
          <span>Fill Opacity</span>
          <span>({{ fillOpacitySetting * 10 }})</span>
        </label>
        <input v-model='fillOpacitySetting' disabled
               type='range' step='0.1' min='0' max='1' class='disabled-setting slider' id='fill_opacity'/>
      </div>
    </div>
  </div>
  <div v-else-if="['RECTANGLE', 'CIRCLE'].includes(settings.drawingMode)" class='sl-w-245px sl-mt-10px'>
    <div>
      <div class='color-label'>Stroke Color</div>
      <div class='sl-flex-only sl-mb-10px sl-ml-10px'>
        <ColorButton v-for='color in colors'
          :color='color'
          :current_color='strokeColorSetting'
          @set-color='setStrokeColor(color)'
        />
      </div>
    </div>
    <div>
      <div class='color-label'>Fill Color</div>
      <div class='sl-flex-only sl-mb-20px sl-ml-10px'>
        <ColorButton v-for='color in colors'
          :color='color'
          :current_color='fillColorSetting'
          @set-color='setFillColor(color)'
        />
      </div>
    </div>
    <div class='sl-border-t-1px sl-h-150px sl-flex-align-items-center sl-w-100per'>
      <div class='sl-flex-column sl-flex-align-items-center sl-w-100per'>
        <label class='slider-label'>
          <span>Stroke Weight</span>
          <span>({{ strokeWeightSetting }})</span>
        </label>
        <input v-model='strokeWeightSetting' @input='setStrokeWeight'
               type='range' step='1' min='0' max='10' class='slider' id='stroke_weight'/>

        <label class='slider-label'>
          <span>Stroke Opacity</span>
          <span>({{ strokeOpacitySetting * 10 }})</span>
        </label>
        <input v-model='strokeOpacitySetting' @input='setStrokeOpacity'
               type='range' step='0.1' min='0' max='1' class='slider' id='stroke_opacity'/>

        <label class='slider-label'>
          <span>Fill Opacity</span>
          <span>({{ fillOpacitySetting * 10 }})</span>
        </label>
        <input v-model='fillOpacitySetting' @input='setFillOpacity'
               type='range' step='0.1' min='0' max='1' class='slider' id='fill_opacity'
        />
      </div>
    </div>
  </div>
  <div v-else-if="settings.drawingMode==='TEXT'" class='sl-w-245px'>
    <div class='textarea-container'>
        <textarea @input='addTextToActiveObject()'
                  @focus='textareaFocused(true)'
                  @blur='textareaFocused(false)'
                  v-model='textInput'
                  id='text_input'
                  style='resize: none'
                  placeholder='Add text here...'
                  maxlength='100'
                  class='map-texarea sl-paragraph-med'>
        </textarea>
    </div>
    <div>
      <div class='color-label'>Fill Color</div>
      <div class='sl-flex-only sl-mb-20px sl-ml-10px'>
        <ColorButton v-for='color in colors'
          :color='color'
          :current_color='fillColorSetting'
          @set-color='setTextBoxColor(color)'
        />
      </div>
    </div>
    <div class='sl-border-t-1px sl-h-150px sl-flex-align-items-center sl-w-100per'>
      <div class='sl-flex-column sl-flex-align-items-center sl-w-100per'>
        <label class='slider-label'>
          <span>Size</span>
          <span>({{ textBoxScaleSetting * 10 }})</span>
        </label>
        <input v-model='textBoxScaleSetting' @input='setTextBoxScale'
               type='range' step='0.1' min='0' max='1' class='slider' id='text_box_scale'/>

        <label class='slider-label'>
          <span>Border Radius</span>
          <span>({{ textBoxRadiusSetting / 2 }})</span>
        </label>
        <input v-model='textBoxRadiusSetting' @input='setTextBoxRadius'
               type='range' step='2' min='0' max='20' class='slider' id='text_box_scale_radius'/>

        <label class='slider-label'>
          <span>Opacity</span>
          <span>({{ textBoxOpacitySetting * 10 }})</span>
        </label>
        <input v-model='textBoxOpacitySetting' @input='setTextBoxOpacity'
               type='range' step='0.1' min='0' max='1' class='slider' id='text_box_opacity'
        />
      </div>
    </div>
  </div>
</template>
<script>
import ColorButton from './ColorButton.vue';

export default {
  components: {
    ColorButton,
  },

  props: {
    settings: {
      type: Object,
      required: false,
    },
  },

  watch: {
    // Deep watch the settings object to ensure the settings are updated
    settings: {
      deep: true,
      handler(newVal) {
        this.strokeColorSetting = newVal.strokeColor;
        this.fillColorSetting = newVal.fillColor;
        this.strokeWeightSetting = newVal.strokeWeight;
        this.strokeOpacitySetting = newVal.strokeOpacity;
        this.fillOpacitySetting = newVal.fillOpacity;
        this.textInput = newVal.textInput;
        this.textBoxScaleSetting = newVal.textBoxScale;
        this.textBoxRadiusSetting = newVal.textBoxRadius;
        this.textBoxOpacitySetting = newVal.textBoxOpacity;
      },
    },
  },

  data() {
    return {
      colors: ['#005199', '#569900', '#9F9EA4', '#1F7A8C', '#FF3333', '#F04C25', '#000000'],
      strokeColorSetting: '#FF3333',
      fillColorSetting: '#005199',
      strokeWeightSetting: 3,
      strokeOpacitySetting: 0.8,
      fillOpacitySetting: 0,
      textInput: '',
      textBoxScaleSetting: 1,
      textBoxRadiusSetting: 8,
      textBoxOpacitySetting: 1,
    };
  },

  created() {
    // When a user clicks on a map object, set the text input to the map object's text if it is a comment box
    // One second delay to ensure the map object container is created
    setTimeout(() => {
      const container = document.getElementById('map-object-container');
      if (container) {
        container.addEventListener('click', () => {
          this.textInput = this.settings.textInput;
        });
      }
    }, 1000);
  },

  methods: {
    setStrokeColor(color) {
      this.strokeColorSetting = color;
      this.$emit('set-drawing-setting', 'strokeColor', color);
    },

    setFillColor(color) {
      this.fillColorSetting = color;
      this.$emit('set-drawing-setting', 'fillColor', color);
    },

    setStrokeWeight() {
      this.$emit('set-drawing-setting', 'strokeWeight', this.strokeWeightSetting);
    },

    setStrokeOpacity() {
      this.$emit('set-drawing-setting', 'strokeOpacity', this.strokeOpacitySetting);
    },

    setFillOpacity() {
      this.$emit('set-drawing-setting', 'fillOpacity', this.fillOpacitySetting);
    },

    setTextBoxColor(color) {
      this.fillColorSetting = color;
      this.$emit('set-drawing-setting', 'textBoxColor', color);
    },

    setTextBoxScale() {
      this.$emit('set-drawing-setting', 'textBoxScale', this.textBoxScaleSetting);
    },

    setTextBoxRadius() {
      this.$emit('set-drawing-setting', 'textBoxRadius', this.textBoxRadiusSetting);
    },

    setTextBoxOpacity() {
      this.$emit('set-drawing-setting', 'textBoxOpacity', this.textBoxOpacitySetting);
    },

    addTextToActiveObject() {
      if (this.textInput.length >= 100) {
        alert('Text cannot be longer than 100 characters');
        return;
      }
      this.$emit('set-drawing-setting', 'textInput', this.textInput);
    },
    textareaFocused(focused) {
      this.$emit('set-drawing-setting', 'textareaFocused', focused);
    },
  },
};
</script>
<style scoped>
  .disabled-setting {
    opacity: 0.3 !important;
    pointer-events: none;
  }

  .color-label {
    color: #9F9EA4;
    margin: 0 0 5px 10px;
  }

  .slider-label {
    display: flex;
    justify-content: space-between;
    color: #9F9EA4;
    width: 225px;
    margin: 10px 0 5px 0px;
  }

  .slider {
    -webkit-appearance: none;
    width: 225px;
    height: 8px;
    margin-bottom: 5px;
    border-radius: 5px;
    background: #c1bfbf;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #014581;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #005199;
    cursor: pointer;
  }

  .textarea-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 72px;
  }

  .map-texarea {
    width: 205px;
    padding: 5px;
    height: 40px;
    border: 2px solid var(--medium-charcoal);
  }
</style>
