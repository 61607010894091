<template>
  <div class='sl-font-size-16px sl-mb-20px
             sl-bold-text sl-flex-justify-content-space-btwn sl-sticky-header'
      :class="{ 'sl-border-b-1px sl-ptb-10px':showHeaderBorder }"
      style='background-color: #fff;'>
    <div v-if='property' style='color: #333E47;'>
      {{ property.name }}
      <span style="font-size: 20px; font-weight: 200;">|</span>
      {{ property.address }},
      {{ property.city }},
      {{ property.state }}
      {{ property.postal_code }}
    </div>
    <div class='sl-cursor-pointer figma-icon'>
      <dataTransferIcon @click="$emit('show-modal')"/>
    </div>
  </div>
</template>
<script>
import dataTransferIcon from '../../../../../assets/images/icons/Icon-Data-Transfer_Charcoal-Dark-100_16.svg';

export default {
  components: {
    dataTransferIcon,
  },
  props: {
    property: Object,
  },
  data() {
    return {
      showHeaderBorder: false,
    };
  },
  created() {
    this.scrollListener();
  },
  methods: {
    scrollListener() {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 100) {
          this.showHeaderBorder = true;
        } else {
          this.showHeaderBorder = false;
        }
      });
    },
  },
};
</script>
