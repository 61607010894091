<template>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 700px;'>
      <div class='sl-flex-justify-content-space-btwn sl-flex-align-items-center sl-mb-10px'>
        <div class='sl-font-size-16px'>Policy Version</div>
        <div class='sl-bold-text sl-text-align-center'>
          <span v-if='saveMessage' class='sl-blue-text'>{{ saveMessage }}</span>
          <span v-else-if='errorMessage' class='sl-red-text'>{{ errorMessage }}</span>
        </div>
        <div class='sl-flex-justify-content-end'>
          <closeIcon class='figma-icon' @click="$emit('closeModal')"/>
        </div>
      </div>
      <div class='sl-flex-column sl-border-t-2px-primary-blue sl-ptb-10px'>

        <form @submit.prevent='save' class='sl-mb-20px sl-pb-20px sl-border-b-1px sl-h-45px'>
          <label class='sl-font-size-14px disabled-font sl-mt-10px'>Name</label>
          <div class='sl-flex-align-items-center sl-flex-justify-content-space-btwn sl-mt-5px sl-h-28px'>
            <input v-model='versionName'
                   type='text'
                   required
                   :disabled='importing'
                   placeholder='Add name here...'
                   class='sl-border-1px-medium-charcoal sl-h-28px sl-pl-5px sl-w-300px'>
            <div v-if="importing === 'save'" class='sl-flex-only sl-align-items-center'>
              <span>Saving Policy Version...</span>
              <div class='sl-spinning-wheel-20px sl-mlr-10px'></div>
            </div>
            <button v-else type='submit' class='sl-w-85px sl-simple-outline-btn'
                    :class="{'sl-simple-outline-btn-disabled': importing}">Save</button>
          </div>
        </form>

        <PolicyVersionFileImport
          :policyVersion='policyVersion'
          :importing='importing'
          :fileType="'policies'"
          :displayName="'Policies'"
          :count='policyVersion.policies'
          @importFile='importFile'
        />
        <PolicyVersionFileImport
          :policyVersion='policyVersion'
          :importing='importing'
          :fileType="'zip_codes'"
          :displayName="'Zip Codes'"
          :count='policyVersion.zip_codes'
          @importFile='importFile'
        />
        <PolicyVersionFileImport
          :policyVersion='policyVersion'
          :importing='importing'
          :fileType="'property_types'"
          :displayName="'Property Types'"
          :count='policyVersion.property_types'
          @importFile='importFile'
        />
      </div>
    </div>
  </div>
</template>
<script>
import PolicyVersionFileImport from './PolicyVersionFileImport.vue';
import closeIcon from '../../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  components: {
    PolicyVersionFileImport,
    closeIcon,
  },

  props: {
    policyVersion: {
      type: Object,
      required: true,
    },
    saveMessage: {
      type: String,
      required: false,
    },
    importing: {
      type: String,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      versionName: this.policyVersion.name,
    };
  },

  methods: {
    save() {
      if (this.policyVersion.id) {
        this.$emit('update', this.versionName);
      } else {
        this.$emit('create', this.versionName);
      }
    },

    importFile(fileType, displayName, file) {
      if (!this.confirmImport(displayName)) { return; }
      this.$emit('importFile', fileType, displayName, file);
    },

    confirmImport(displayName) {
      let message;
      if (displayName === 'Policies' && this.policyVersion.policies > 0) {
        message = 'Are you sure you want to import Policies? '
                  + 'This will overwrite the existing policies and delete the '
                  + 'existing zip codes and property types for this version.';
        return window.confirm(message);
      } if (displayName === 'Zip Codes' && this.policyVersion.zip_codes > 0) {
        message = 'Are you sure you want to import Zip Codes? '
                  + 'This will overwrite the existing zip codes for this version.';
        return window.confirm(message);
      } if (displayName === 'Property Types' && this.policyVersion.property_types > 0) {
        message = 'Are you sure you want to import Property Types? '
                  + 'This will overwrite the existing property types for this version.';
        return window.confirm(message);
      }
      return true;
    },
  },
};
</script>
