<template>
  <div class='sl-border-t-1px sl-w-100per sl-mt-10px'>
    <label for='portrait' class='sl-custom-radio sl-w-180px disabled-font sl-mt-20px sl-ml-20px'>
      Portrait
      <input @click="toggleAspect(true)" id='portrait' type='radio' :checked="aspect===true"/>
      <span class='checkmark'></span>
    </label>
    <label for='landscape' class='sl-custom-radio sl-w-180px disabled-font sl-mt-10px sl-ml-20px'>
      Landscape
      <input @click="toggleAspect(false)" id='landscape' type='radio' :checked="aspect===false"/>
      <span class='checkmark'></span>
    </label>
  </div>
</template>
<script>
export default {
  props: {
    aspect: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    toggleAspect(aspect) {
      this.$emit('toggle-aspect', aspect);
    },
  },
};
</script>
