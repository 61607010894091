<template>
  <SpinningModal v-if='showLoading'/>
  <AlertMessage :message='alertMessage'/>
  <PageHeader :title='documentType' :saved='mapSaved'/>
  <div v-if='dataReady' class='sl-flex-justify-content-center'>
    <!-- Left Side Pannel -->
    <div id='left_side_pannel'>
      <div class='sl-w-100per'>
        <div class='sl-border-t-1px sl-border-b-1px sl-ptb-10px'>
          <DrawingTools
            :drawingMode='settings.drawingMode'
            @set-drawing-mode='setDrawingMode'
          />
        </div>
        <div class='sl-flex-align-items-center sl-flex-column'>
          <DrawingSettings
            :settings='settings'
            @set-drawing-setting='setDrawingSetting'
          />
          <PortraitLandscapeSelector
            :aspect='aspect'
            @toggle-aspect='toggleAspect'
          />
          <MapTypeSelector
            :mapType='documentType'
            @toggle-map-type='toggleMapType'
            @copy-map='copyMap'
          />
        </div>
        <SaveButton @save='save'/>
      </div>
    </div>
    <!-- Map Div -->
    <MapContainer
      :locationIsSet='locationIsSet'
      :dataReady='dataReady'
      :aspect='aspect'
    />
    <!-- Right Side Pannel -->
    <div id='right_side_pannel'>
      <!-- List of the map objects -->
      <MapObjects
        :mapObjects='mapObjects'
        :activeIndex='activeIndex'
        @pan-to-location='panToLocation'
        @set-map-object-active='setMapObjectActive'
        @delete-map-object='deleteMapObject'
      />
      <div class='sl-pt-20px sl-w-90per sl-plr-5px'>
        <!-- Site Map Download Link -->
        <DocumentDownloadLink
          :documentId='sitemapdocId'
          :documentType='documentType'
          :documentLink='siteMapLink'
          :figure="'Figure 1'"
        />
        <!-- Site Plan Download Link -->
        <DocumentDownloadLink
          :documentId='siteplandocId'
          :documentType='documentType'
          :documentLink='sitePlanLink'
          :figure="'Figure 2'"
        />
        <FullScreenDrawingManager
          :key='fullScreenDrawingManagerKey'
          :fullScreen='fullScreen'
          :settings='settings'
          :mapObjects='mapObjects'
          :activeIndex='activeIndex'
          :alertMessage='alertMessage'
          :showLoading='showLoading'
          @set-drawing-mode='setDrawingMode'
          @set-drawing-setting='setDrawingSetting'
          @pan-to-location='panToLocation'
          @set-map-object-active='setMapObjectActive'
          @delete-map-object='deleteMapObject'
          @save='save'
        />
      </div>
      <CreateDocumentButton @create-document='createDocument'/>
    </div>
  </div>
  <!-- Used for HTML2Canvas snapshot -->
  <div id='newCanvas'></div>
</template>
<script>
// @ts-nocheck
import axios from 'axios';
import { toRaw } from 'vue';
import { set } from 'lodash';
import PageHeader from './PageHeader.vue';
import PortraitLandscapeSelector from './PortraitLandscapeSelector.vue';
import MapTypeSelector from './MapTypeSelector.vue';
import DrawingSettings from './DrawingSettings.vue';
import FullScreenDrawingManager from './FullScreenDrawingManager.vue';
import DrawingTools from './DrawingTools.vue';
import MapContainer from './MapContainer.vue';
import MapObjects from './MapObjects.vue';
import DocumentDownloadLink from './DocumentDownloadLink.vue';
import SaveButton from './SaveButton.vue';
import CreateDocumentButton from './CreateDocumentButton.vue';
import SpinningModal from '../../components/SpinningModal.vue';
import AlertMessage from '../../components/AlertMessage.vue';

export default {
  components: {
    PageHeader,
    PortraitLandscapeSelector,
    MapTypeSelector,
    DrawingSettings,
    FullScreenDrawingManager,
    DocumentDownloadLink,
    DrawingTools,
    MapContainer,
    MapObjects,
    SaveButton,
    CreateDocumentButton,
    SpinningModal,
    AlertMessage,
  },

  data() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      projectId: urlParams.get('id'),
      projectScopeId: urlParams.get('project_scope_id'),
      project: null,
      colors: ['#005199', '#569900', '#9F9EA4', '#1F7A8C', '#FF3333', '#F04C25', '#000000'],
      googleMap: null,
      siteMap: null,
      location: {},
      locationIsSet: false,
      sitemapdocId: null,
      siteplandocId: null,
      locationMarker: null,
      // Map objects reprent the objects saved in the database
      mapObjects: [],
      // Map drawings reprent the objects passed into the map interface
      mapDrawings: [],
      activeIndex: null,
      drawingManager: null,
      overlayLimit: 15,
      showLoading: false,
      alertMessage: null,
      aspect: true,
      documentType: 'Site Map',
      dataReady: false,
      mapSaved: true,
      textareaFocused: false,
      fullScreenDrawingManagerKey: 0,
      fullScreen: false,
      settings: {
        drawingMode: 'POINTER',
        strokeColor: '#FF3333',
        fillColor: '#005199',
        strokeWeight: 3,
        strokeOpacity: 0.8,
        fillOpacity: 0,
        textBoxOpacity: 1,
        textBoxRadius: 8,
        textBoxScale: 1,
        textInput: null,
      },
    };
  },

  mounted() {
    this.fetchMap();
    this.setPageExitPrompt();
  },

  methods: {
    // Fetch the map data from the database
    async fetchMap() {
      this.showLoading = true;
      await axios.get('/site_maps/project_data', {
        params: {
          project_id: this.projectId,
          project_scope_id: this.projectScopeId,
          document_type: this.documentType,
        },
      })
        .then((response) => {
          this.project = response.data.project;
          this.siteMap = response.data.site_map;
          this.mapObjects = response.data.map_objects;
          this.location = response.data.location;
          this.sitemapdocId = response.data.sitemapdocId;
          this.siteplandocId = response.data.siteplandocId;
          this.aspect = response.data.orientation;
          if (this.siteMap.lat && this.siteMap.lng) {
            this.locationIsSet = true;
          }
          this.initialize();
          this.dataReady = true;
          this.mapSaved = true;
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, map not loaded';
        })
        .finally(() => {
          setTimeout(() => {
            this.showLoading = false;
          }, 500);
        });
    },

    // Create Map
    async initialize() {
      const { Map } = await google.maps.importLibrary('maps');
      const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
      const map = new Map(document.getElementById('map'), {
        center: { lat: parseFloat(this.siteMap.lat), lng: parseFloat(this.siteMap.lng) },
        zoom: parseInt(this.siteMap.zoom),
        mapTypeId: this.siteMap.map_type,
        tilt: parseFloat(this.siteMap.tilt),
        mapId: '7427cd8c9a3fcba3',
      });
      const location = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(this.location.lat), lng: parseFloat(this.location.lng) },
        map,
      });
      this.locationMarker = location;
      this.createListeners(map);
      this.loadDrawingManager(map);
      this.loadMapObjects(map);
      this.googleMap = map;
    },

    // Event listeners for when the map is interacted with, updates the site map data
    createListeners(map) {
      map.addListener('click', () => {
        this.setAllEditable(false);
        this.activeIndex = null;
      });
      map.addListener('center_changed', () => {
        this.siteMap.lat = map.center.lat();
        this.siteMap.lng = map.center.lng();
        this.mapSaved = false;
      });
      map.addListener('zoom_changed', () => {
        this.siteMap.zoom = map.zoom;
        this.mapSaved = false;
      });
      map.addListener('maptypeid_changed', () => {
        this.siteMap.map_type = map.mapTypeId;
        this.mapSaved = false;
      });
      map.addListener('tilt_changed', () => {
        this.siteMap.tilt = map.tilt;
        this.mapSaved = false;
      });
      map.addListener('bounds_changed', () => {
        const mapContainer = this.googleMap.getDiv().children[0];
        if (mapContainer.clientWidth == window.innerWidth && mapContainer.clientHeight == window.innerHeight ) {
          this.fullScreen = true;
        } else {
          this.fullScreen = false;
        }
      });
      // Add event listener to change the drawing mode when letter is pressed
      document.addEventListener('keydown', (event) => {
        const textarea = document.getElementById('text_input');
        // If the text box is focused, do not allow the drawing mode to be changed with the keyboard
        if (this.textareaFocused) {return;}
        if (event.key === 'p') {
          this.setDrawingMode('POINTER');
        } else if (event.key === 'l') {
          this.setDrawingMode('POLYLINE');
        } else if (event.key === 'r') {
          this.setDrawingMode('RECTANGLE');
        } else if (event.key === 'c') {
          this.setDrawingMode('CIRCLE');
        } else if (event.key === 't') {
          this.setDrawingMode('TEXT');
        } else if (event.key === 'm') {
          this.setDrawingMode('MARKER');
        }
      });
    },


    // Move the map to the property location
    panToLocation() {
      this.activeIndex = null;
      this.setAllEditable(false);
      this.setDrawingMode('POINTER');
      this.googleMap.panTo(this.locationMarker.position);
    },

    // Load map objects that are saved in the database
    loadMapObjects(map) {
      this.mapDrawings = [];
      this.mapObjects.forEach((object, index) => {
        switch (object.type) {
          case 'Line':
            this.loadLine(object, map, index);
            break;
          case 'Rectangle':
            this.loadRectangle(object, map, index);
            break;
          case 'Circle':
            this.loadCircle(object, map, index);
            break;
          case 'Text':
            this.loadText(object, map, index);
            break;
          case 'Marker':
            this.loadMarker(object, map, index);
            break;
        }
      });
    },

    // Load line map objects
    loadLine(data, map, index) {
      const points = [];
      data.points.forEach((datapoint) => {
        const point = new google.maps.LatLng(datapoint.lat, datapoint.lng);
        points.push(point);
      });
      const line = new google.maps.Polyline({
        index: index,
        type: data.type,
        path: points,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        editable: false,
        map,
      });
      line.addListener('click', () => {
        // When a line is clicked, update the drawing settings to match the line,
        // set the drawing mode to pointer so the user can still move the line
        this.activeIndex = index;
        const activeObject = this.mapObjects[index];
        this.setAllEditable(false);
        line.setEditable(true);
        this.setActiveObjectDrawingSettings(activeObject, line);
        this.setDrawingMode('POINTER');
      });
      this.mapDrawings.push(line);
    },

    // Load rectangle map objects
    loadRectangle(data, map, index) {
      const rectangle = new google.maps.Rectangle({
        index: index,
        type: data.type,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        fillColor: data.fill_color,
        fillOpacity: data.fill_opacity,
        editable: false,
        map,
        bounds: {
          north: parseFloat(data.north),
          south: parseFloat(data.south),
          east: parseFloat(data.east),
          west: parseFloat(data.west),
        },
      });
      rectangle.addListener('click', () => {
        this.activeIndex = index;
        const activeObject = this.mapObjects[index];
        this.setAllEditable(false);
        rectangle.setEditable(true);
        this.setActiveObjectDrawingSettings(activeObject, rectangle);
        this.setDrawingMode('POINTER');
      });
      this.mapDrawings.push(rectangle);
    },

    // Load circle map objects
    loadCircle(data, map, index) {
      const circle = new google.maps.Circle({
        index: index,
        type: data.type,
        strokeColor: data.stroke_color,
        strokeOpacity: data.stroke_opacity,
        strokeWeight: data.stroke_weight,
        fillColor: data.fill_color,
        fillOpacity: data.fill_opacity,
        editable: false,
        map,
        center: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        radius: parseFloat(data.radius),
      });
      circle.addListener('click', () => {
        this.activeIndex = index;
        const activeObject = this.mapObjects[index];
        this.setAllEditable(false);
        circle.setEditable(true);
        this.setActiveObjectDrawingSettings(activeObject, circle);
        this.setDrawingMode('POINTER');
      });
      this.mapDrawings.push(circle);
    },

    // Load text map objects
    loadText(data, map, index) {
      const mapTextBox = document.createElement('div');
      mapTextBox.id = `text_box_${index}`;
      mapTextBox.className = 'site-map-text-box';
      mapTextBox.style.backgroundColor = data.fill_color;
      mapTextBox.style.borderRadius = `${data.border_radius}px`;
      mapTextBox.style.transform = `scale(${data.scale})`;
      mapTextBox.textContent = data.content;
      this.setTextBoxDownArrowColor(index, data.fill_color);

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        content: mapTextBox,
        gmpDraggable: true,
        title: 'Text',
        map,
      });
      mapTextBox.addEventListener('mousedown', () => {
        this.activeIndex = index;
        const activeObject = this.mapObjects[index];
        this.setAllEditable(false);
        this.setActiveObjectDrawingSettings(activeObject, marker);
        this.setDrawingMode('POINTER');
      });
      mapTextBox.addEventListener('mouseup', () => {
        this.mapSaved = false;
        this.mapObjects[index].lat = marker.position.lat;
        this.mapObjects[index].lng = marker.position.lng;
      });
      // Double click to edit text
      mapTextBox.addEventListener('dblclick', () => {
        this.activeIndex = index;
        this.setAllEditable(false);
        this.setMapObjectActive(index);
      });
      this.mapDrawings.push(marker);
    },

    // Load marker map objects
    loadMarker(data, map, index) {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(data.lat), lng: parseFloat(data.lng) },
        gmpDraggable: true,
        title: 'Marker',
        map,
      });
      marker.addListener('click', () => {
        this.activeIndex = index;
        this.setAllEditable(false);
      });
      marker.addListener('drag', () => {
        this.mapSaved = false;
        this.activeIndex = index;
        this.setAllEditable(false);
      });
      this.mapDrawings.push(marker);
    },

    // Create the drawing manager
    loadDrawingManager(map) {
      const drawing_options = {
        strokeColor: this.settings.strokeColor,
        fillColor: this.settings.fillColor,
        strokeWeight: this.settings.strokeWeight,
        strokeOpacity: this.settings.strokeOpacity,
        fillOpacity: this.settings.fillOpacity,
        clickable: true,
        editable: true,
        zIndex: 1,
      };
      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {},
        polygonOptions: drawing_options,
        polylineOptions: drawing_options,
        rectangleOptions: drawing_options,
        circleOptions: drawing_options,
      });
      drawingManager.setMap(map);
      // Marker is added to the map
      google.maps.event.addListener(drawingManager, 'markercomplete', (item) => {
        this.mapSaved = false;
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        // The drawing manager creates a legacy marker by default.
        // Create an advanced marker instead and remove the legacy marker from the map.
        item.setMap(null);
        const index = this.mapObjects.length;
        let marker = new google.maps.marker.AdvancedMarkerElement({
          position: item.getPosition(),
          gmpDraggable: true,
          map,
        });

        // Text box is added to the map
        if (this.settings.drawingMode === 'TEXT') {
          this.settings.textInput = document.getElementById('text_input').value;
          // Custom div element for text box
          const mapTextBox = document.createElement('div');
          mapTextBox.id = `text_box_${index}`;
          mapTextBox.className = 'site-map-text-box';
          mapTextBox.style.backgroundColor = this.convertHexToRgb(this.settings.fillColor);
          this.setTextBoxDownArrowColor(index, this.settings.fillColor);
          mapTextBox.style.borderRadius = `${this.settings.textBoxRadius}px`;
          mapTextBox.style.transform = `scale(${this.settings.textBoxScale})`;
          mapTextBox.textContent = this.settings.textInput;
          marker.content = mapTextBox;
          marker.title = 'Text';
          mapTextBox.addEventListener('mousedown', () => {
            this.activeIndex = index;
            const activeObject = this.mapObjects[index];
            this.setAllEditable(false);
            this.setActiveObjectDrawingSettings(activeObject, marker);
            this.setDrawingMode('POINTER');
          });
          mapTextBox.addEventListener('mouseup', () => {
            this.mapSaved = false;
            this.mapObjects[index].lat = marker.position.lat;
            this.mapObjects[index].lng = marker.position.lng;
          });
          // Double click to edit text
          mapTextBox.addEventListener('dblclick', () => {
            this.activeIndex = index;
            this.setAllEditable(false);
            this.setMapObjectActive(index);
          });
          this.mapObjects.push({
            type: 'Text',
            index,
            lat: item.getPosition().lat(),
            lng: item.getPosition().lng(),
            content: this.settings.textInput,
            fill_color: this.settings.fillColor,
            fill_opacity: this.settings.textBoxOpacity,
            border_radius: this.settings.textBoxRadius,
            scale: this.settings.textBoxScale,
          });
        // Marker is added to the map
        } else {
          marker.title = 'Marker';
          this.mapObjects.push({
            type: 'Marker',
            index,
            lat: item.getPosition().lat(),
            lng: item.getPosition().lng(),
          });
        }
        this.activeIndex = index;
        this.mapDrawings.push(marker);
        this.disablePreviousEditable();

        item.addListener('click', () => {
          this.activeIndex = index;
          this.setAllEditable(false);
        });
        item.addListener('dragend', () => {
          this.mapSaved = false;
          this.activeIndex = index;
          this.setAllEditable(false);
        });
        
      });
      // Rectangle is added to the map
      google.maps.event.addListener(drawingManager, 'rectanglecomplete', (item) => {
        this.mapSaved = false;
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        const index = this.mapObjects.length;
        this.mapObjects.push({
          type: 'Rectangle',
          index,
          north: item.getBounds().getNorthEast().lat(),
          south: item.getBounds().getSouthWest().lat(),
          east: item.getBounds().getNorthEast().lng(),
          west: item.getBounds().getSouthWest().lng(),
          stroke_color: item.strokeColor,
          fill_color: item.fillColor,
          stroke_opacity: item.strokeOpacity,
          fill_opacity: item.fillOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
        });
        item.addListener('click', () => {
          this.activeIndex = index;
          const activeObject = this.mapObjects[index];
          this.setAllEditable(false);
          item.setEditable(true);
          this.setActiveObjectDrawingSettings(activeObject, item);
          this.setDrawingMode('POINTER');
        });
        item.set('type', 'Rectangle');
        this.activeIndex = index;
        this.mapDrawings.push(item);
        this.disablePreviousEditable();
      });
      // Circle is added to the map
      google.maps.event.addListener(drawingManager, 'circlecomplete', (item) => {
        this.mapSaved = false;
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        const index = this.mapObjects.length;
        this.mapObjects.push({
          type: 'Circle',
          index,
          lat: item.getCenter().lat(),
          lng: item.getCenter().lng(),
          radius: item.getRadius(),
          stroke_color: item.strokeColor,
          fill_color: item.fillColor,
          stroke_opacity: item.strokeOpacity,
          fill_opacity: item.fillOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
        });
        item.addListener('click', () => {
          this.activeIndex = index;
          const activeObject = this.mapObjects[index];
          this.setAllEditable(false);
          item.setEditable(true);
          this.setActiveObjectDrawingSettings(activeObject, item);
          this.setDrawingMode('POINTER');
        });
        item.set('type', 'Circle');
        this.activeIndex = index;
        this.mapDrawings.push(item);
        this.disablePreviousEditable();
      });
      // Line is added to the map
      google.maps.event.addListener(drawingManager, 'polylinecomplete', (item) => {
        this.mapSaved = false;
        if (this.mapObjects.length >= this.overlayLimit) {
          alert('You have reached the limit of items that can be added to the map. Please delete map items to add more.');
          return;
        }
        const index = this.mapObjects.length;
        const points = (item.getPath().getArray().map((point) => ({ lat: point.lat(), lng: point.lng() })));
        this.mapObjects.push({
          type: 'Line',
          index,
          stroke_color: item.strokeColor,
          stroke_opacity: item.strokeOpacity,
          stroke_weight: item.strokeWeight,
          z_index: item.zIndex,
          points,
        });
        item.addListener('click', () => {
          this.activeIndex = index;
          const activeObject = this.mapObjects[index];
          this.setAllEditable(false);
          item.setEditable(true);
          this.setActiveObjectDrawingSettings(activeObject, item);
          this.setDrawingMode('POINTER');
        });
        item.set('type', 'Line');
        this.activeIndex = index;
        this.mapDrawings.push(item);
        this.disablePreviousEditable();
      });

      this.drawingManager = drawingManager;
    },

    // Set the drawing mode of the left side panel and the drawing manager
    setDrawingMode(mode) {
      this.settings.drawingMode = mode;
      this.settings.textInput = null;
      if (mode === 'TEXT') {
        this.drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType.MARKER,
        });
      } else {
        this.drawingManager.setOptions({
          drawingMode: google.maps.drawing.OverlayType[mode],
        });
      }
    },

    // Calls the appropriate method to set the drawing setting
    setDrawingSetting(setting, value) {
      switch (setting) {
        case 'strokeColor':
          this.setStrokeColor(value);
          break;
        case 'fillColor':
          this.setFillColor(value);
          break;
        case 'strokeWeight':
          this.setStrokeWeight(value);
          break;
        case 'strokeOpacity':
          this.setStrokeOpacity(value);
          break;
        case 'fillOpacity':
          this.setFillOpacity(value);
          break;
        case 'textBoxColor':
          this.setTextBoxColor(value);
          break;
        case 'textBoxScale':
          this.setTextBoxScale(value);
          break;
        case 'textBoxRadius':
          this.setTextBoxRadius(value);
          break;
        case 'textBoxOpacity':
          this.setTextBoxOpacity(value);
          break;
        case 'textInput':
          this.addTextToActiveObject(value);
          break;
        case 'textareaFocused':
          this.textareaFocused = value;
          break;
      }
    },

    // Set the stroke color of the drawing manager and the map objects
    setStrokeColor(color) {
      this.settings.strokeColor = color;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing) {
        activeDrawing.set('strokeColor', color);
        activeObject.stroke_color = color;
      }
    },

    // Set the fill color of the drawing manager and the map objects
    setFillColor(color) {
      this.settings.fillColor = color;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing) {
        activeDrawing.set('fillColor', color);
        activeObject.fill_color = color;
      }
    },

    // Set the stroke weight of the drawing manager and the map objects
    setStrokeWeight(strokeWeight) {
      this.settings.strokeWeight = strokeWeight;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing) {
        activeDrawing.set('strokeWeight', strokeWeight);
        activeObject.stroke_weight = strokeWeight;
      }
    },

    // Set the stroke opacity of the drawing manager and the map objects
    setStrokeOpacity(opacity) {
      this.settings.strokeOpacity = opacity;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing) {
        activeDrawing.set('strokeOpacity', opacity);
        activeObject.stroke_opacity = opacity;
      }
    },

    // Set the fill opacity of the drawing manager and the map objects
    setFillOpacity(opacity) {
      this.settings.fillOpacity = opacity;
      this.updateDrawingManager();
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeDrawing) {
        activeDrawing.set('fillOpacity', opacity);
        activeObject.fill_opacity = opacity;
      }
    },

    // Set the text box color, using rgba values so the opacity is maintained in the PDF
    setTextBoxColor(color) {  
      this.settings.fillColor = color;
      const activeDrawing = this.mapDrawings[this.activeIndex];
      const rgbValue = this.convertHexToRgb(color);
      let backgroundColor = activeDrawing.content.style.backgroundColor;
      let colorArray = backgroundColor.split(',');
      // If there is an opacity value, update the opacity in the text box
      if (colorArray.length === 4) {
        const opacity = colorArray[3].replace(')', '');
        let rgbArray = rgbValue.split(',');
        rgbArray[2] = rgbArray[2].replace(')', '');
        rgbArray.push(` ${opacity})`);
        backgroundColor = rgbArray.join(',');
      } else {
        // If there is no opacity value, the rgb value only has 3 values
        backgroundColor = rgbValue;
      }
      activeDrawing.content.style.backgroundColor = backgroundColor;
      this.mapObjects[this.activeIndex].fill_color = backgroundColor;
      this.setTextBoxDownArrowColor(this.activeIndex, backgroundColor);
    },

    // Resize the text box, the size does not automatically change with zoom
    setTextBoxScale(scale) {
      this.settings.textBoxScale = scale;
      const activeDrawing = this.mapDrawings[this.activeIndex];
      activeDrawing.content.style.transform = `scale(${scale})`;
    },

    // Change the border radius of the text box
    setTextBoxRadius(radius) {
      this.settings.textBoxRadius = radius;
      const activeDrawing = this.mapDrawings[this.activeIndex];
      activeDrawing.content.style.borderRadius = `${radius}px`;
    },

    // Change the opacity of the text box, using rgba values so the opacity is maintained in the PDF
    setTextBoxOpacity(opacity) {
      this.settings.textBoxOpacity = opacity;
      const activeDrawing = this.mapDrawings[this.activeIndex];
      let backgroundColor = activeDrawing.content.style.backgroundColor;
      let array = backgroundColor.split(',');
      // If there is an opacity value, update the opacity in the text box
      if (array.length === 4) {
        array[3] = ` ${opacity})`;
      } else {
      // If there is no opacity value, the rgb value only has 3 values
        array[2] = array[2].replace(')', '');
        array.push(` ${opacity})`);
      }
      backgroundColor = array.join(',');
      activeDrawing.content.style.backgroundColor = backgroundColor;
      this.mapObjects[this.activeIndex].fill_color = backgroundColor;
      this.setTextBoxDownArrowColor(this.activeIndex, backgroundColor);
    },

    // Adjust the psuedo element (::after) color, down arrow below the text box
    setTextBoxDownArrowColor(index, color) {
      const style = document.createElement('style');
      document.head.appendChild(style);
      style.sheet.insertRule(`
        #text_box_${index}::after {
          border-top-color: ${color};
        }
      `, style.sheet.cssRules.length);
    },

    setTextareaFocused(focused) {
      this.textareaFocused = focused;
    },

    // Update the drawing manager with the current drawing settings, restrct settings by drawing mode
    updateDrawingManager() {
      const drawing_options = {
        strokeColor: this.settings.strokeColor,
        fillColor: this.settings.fillColor,
        strokeWeight: this.settings.strokeWeight,
        strokeOpacity: this.settings.strokeOpacity,
        fillOpacity: this.settings.fillOpacity,
        clickable: true,
        editable: true,
        zIndex: 1,
      };
      this.drawingManager.setOptions({
        polygonOptions: drawing_options,
        polylineOptions: drawing_options,
        rectangleOptions: drawing_options,
        circleOptions: drawing_options,
      });
    },

    // Add or remove the editable property from all map objects
    setAllEditable(editable) {
      this.mapDrawings.forEach((drawing) => {
        if (drawing.setEditable) {
          drawing.setEditable(editable);
        }
      });
    },

    // Set the most recent map object to editable and all other map objects to not editable
    disablePreviousEditable() {
      const index = this.mapDrawings.length - 1;
      this.setAllEditable(false);
      if (this.mapDrawings[index]?.setEditable) {
        this.mapDrawings[index].setEditable(true);
      }
    },

    // Show the text box when the drawing manager is in text mode or a text object is active
    showTextarea() {
      const activeObject = this.mapObjects[this.activeIndex];
      return this.settings.drawingMode === 'TEXT' || (activeObject && activeObject.type === 'Text' && activeObject.content);
    },

    // Add text to text box when it is in active mode
    addTextToActiveObject(textInput) {
      this.mapSaved = false;
      const activeObject = this.mapObjects[this.activeIndex];
      if (activeObject && activeObject.type === 'Text') {
        const activeDrawing = this.mapDrawings[this.activeIndex];
        if (activeDrawing) {
          activeDrawing.content.textContent = textInput;
          this.mapObjects[this.activeIndex].content = textInput;
        }
      }
    },

    // Save map and all map objects
    async save(params) {
      this.showLoading = true;
      if (this.updateMapObjects()) {
        await axios.put(`/site_maps/${this.siteMap.id}`, null, {
          params: {
            site_map: this.siteMap,
            map_objects: this.mapObjects,
          },
        })
          .then(() => {
            this.mapSaved = true;
            this.setAllEditable(false);
            this.settings.textInput = null;
            setTimeout(() => {
              this.showLoading = false;
            }, 500);
            if (params.alert) {
              this.alertMessage = 'Map Saved';
              setTimeout(() => {
                this.alertMessage = '';
              }, 3000);
            }
          })
          .catch(() => {
            this.alertMessage = 'Something went wrong, map not saved';
            this.showLoading = false;
          });
      } else {
        this.showLoading = false;
      }
    },

    // Before sending data to the server, update all map objects with the current map drawing settings
    updateMapObjects() {
      let valid = true;
      this.mapDrawings.forEach((drawing, index) => {
        if (drawing.type === 'Line') {
          this.mapObjects[index].points = drawing.getPath().getArray().map((point) => (
            { lat: point.lat(), lng: point.lng() }
          ));
          if (this.mapObjects[index].points.length > 30) {
            this.alertMessage = 'Line has too many points, please draw the line with less than 30 points';
            valid = false;
          }
        } else if (drawing.type === 'Rectangle') {
          this.mapObjects[index].north = drawing.getBounds().getNorthEast().lat();
          this.mapObjects[index].south = drawing.getBounds().getSouthWest().lat();
          this.mapObjects[index].east = drawing.getBounds().getNorthEast().lng();
          this.mapObjects[index].west = drawing.getBounds().getSouthWest().lng();
        } else if (drawing.type === 'Circle') {
          this.mapObjects[index].lat = drawing.getCenter().lat();
          this.mapObjects[index].lng = drawing.getCenter().lng();
          this.mapObjects[index].radius = drawing.getRadius();
        } else if (drawing.title === 'Marker') {
          this.mapObjects[index].lat = drawing.position.lat;
          this.mapObjects[index].lng = drawing.position.lng;
        } else if (drawing.title === 'Text') {
          this.mapObjects[index].lat = drawing.position.lat;
          this.mapObjects[index].lng = drawing.position.lng;
          this.mapObjects[index].content = drawing.content.textContent;
          this.mapObjects[index].fill_color = drawing.content.style.backgroundColor;
          this.mapObjects[index].fill_opacity = drawing.content.style.opacity;
          this.mapObjects[index].border_radius = drawing.content.style.borderRadius.replace('px', '');
          this.mapObjects[index].scale = drawing.content.style.transform.split('(')[1].split(')')[0];
        }
      });
      return valid;
    },

    // Delete a single map object
    async deleteMapObject(index) {
      toRaw(this.mapDrawings[index]).setMap(null);
      this.mapDrawings.splice(index, 1);
      this.mapObjects.splice(index, 1);
      this.mapSaved = false;
    },

    // Sets a single map object to active and the drawing mode to that object's type
    setMapObjectActive(index) {
      const activeObject = this.mapObjects[index];
      const activeDrawing = this.mapDrawings[index];
      if (activeObject?.type === 'Text') {
        this.setDrawingMode('TEXT');
      } else if (activeObject?.type === 'Line') {
        this.setDrawingMode('POLYLINE');
      } else if (activeObject?.type === 'Rectangle') {
        this.setDrawingMode('RECTANGLE');
      } else if (activeObject?.type === 'Circle') {
        this.setDrawingMode('CIRCLE');
      } else if (activeObject?.type === 'Marker') {
        this.setDrawingMode('MARKER');
      } else {
        this.setDrawingMode('POINTER');
      }
      this.activeIndex = index;

      this.setAllEditable(false);
      // Most of the logic below handles the panning of the map to the active object
      if (activeObject && activeObject.type) {
        if (this.mapDrawings[index].setEditable) {
          this.mapDrawings[index].setEditable(true);
        }
        if (['Circle', 'Marker'].includes(activeObject.type)) {
          const position = { lat: parseFloat(activeObject.lat), lng: parseFloat(activeObject.lng) };
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Text') {
          const position = { lat: parseFloat(activeObject.lat), lng: parseFloat(activeObject.lng) };
          this.setActiveTextBoxDrawingSettings(activeObject, activeDrawing);
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Rectangle') {
          const difference1 = (parseFloat(activeObject.south) - parseFloat(activeObject.north)) / 2;
          const difference2 = (parseFloat(activeObject.east) - parseFloat(activeObject.west)) / 2;
          const position = {
            lat: parseFloat(activeObject.south) + difference1,
            lng: parseFloat(activeObject.west) + difference2,
          };
          this.googleMap.panTo(position);
        } else if (activeObject.type === 'Line') {
          const lat = parseFloat(activeObject.points[0].lat);
          const lng = parseFloat(activeObject.points[0].lng);
          const position = { lat, lng };
          this.googleMap.panTo(position);
        }
        this.setActiveObjectDrawingSettings(activeObject, activeDrawing);
      }
    },

    // Set the left side panel drawing settings to match the active map object
    setActiveObjectDrawingSettings(activeObject, activeDrawing) {
      if (activeObject.type === 'Text') {
        // Special case for text objects, the text box settings are different from the other map objects
        this.setActiveTextBoxDrawingSettings(activeObject, activeDrawing);
        // All other map objects, besides the markers
      } else if (activeObject.type != 'Marker') {
        this.settings.strokeWeight = parseInt(activeDrawing.strokeWeight);
        this.settings.strokeOpacity = parseFloat(activeDrawing.strokeOpacity);
        this.settings.strokeColor = activeDrawing.strokeColor;
        if (activeDrawing.fillOpacity) {
          this.settings.fillOpacity = parseFloat(activeDrawing.fillOpacity);
        }
        if (activeDrawing.fillColor) {
          this.settings.fillColor = activeDrawing.fillColor;
        }
        this.updateDrawingManager();
      }
    },

    // Change the left side panel text box specfic settings to the same as the active text box
    setActiveTextBoxDrawingSettings(activeObject, activeDrawing) {
      this.settings.textBoxScale = activeDrawing.content.style.transform.replace('scale(', '').replace(')', '');
      this.settings.textBoxRadius = activeDrawing.content.style.borderRadius.replace('px', '');
      const backgroundColor = activeDrawing.content.style.backgroundColor;
      const backgroundColorArray = backgroundColor.split(',');
      let rgbValue = backgroundColorArray.slice(0, 3).join(',');
      let opacity = 1;
      if (backgroundColorArray.length === 4) {
        opacity = backgroundColorArray[3].replace(')', '');
        rgbValue += ')'
      }
      this.settings.fillColor = this.convertRgbToHex(rgbValue);
      this.settings.textBoxOpacity = opacity;
      this.settings.textInput = activeObject.content;
      setTimeout(() => {
        const textarea = document.getElementById('text_input');
        if (textarea) {
          textarea.value = activeObject.content;
        }
      }, 50);
    },

    // PDF document creation
    async createDocument() {
      this.showLoading = true;
      this.setAllEditable(false);
      window.scrollTo(0, 0);
      this.hideMapWaterMark();
      await this.hideMapControls()
        .then(() => {
          this.captureMap();
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, document not created';
        });
    },

    // Hide the map controls and location marker before creating the PDF
    hideMapControls() {
      return new Promise((resolve) => {
        this.locationMarker.position = null;
        this.googleMap.setOptions({ disableDefaultUI: true });
        setTimeout(() => {
          resolve('resolved');
        }, 500);
      });
    },

    // Hide the Google watermark from the map before creating the PDF
    hideMapWaterMark() {
      const elements1 = document.querySelectorAll('.map-container-portrait a');
      const elements2 = document.querySelectorAll('.map-container-landscape a');
      elements1.forEach((element) => { element.style.display = 'none'; });
      elements2.forEach((element) => { element.style.display = 'none'; });
    },

    // Show the google watermark after creating the PDF
    showMapWaterMark() {
      const elements1 = document.querySelectorAll('.map-container-portrait a');
      const elements2 = document.querySelectorAll('.map-container-landscape a');
      elements1.forEach((element) => { element.style.display = 'block'; });
      elements2.forEach((element) => { element.style.display = 'block'; });
    },

    // Take a screenshot of the map and send it to the server
    captureMap() {
      const map = document.getElementById('map');
      let img;
      html2canvas(map, { useCORS: true, scale: 4, willReadFrequently: true })
        .then((canvas) => {
          const new_canvas = document.querySelector('#newCanvas').appendChild(canvas);
          img = new_canvas.toDataURL('image/png');
          document.querySelector('#newCanvas').removeChild(canvas);
        })
        .then(() => {
          this.sendDocument(img);
        });
    },

    // Ajax call to the server to create the PDF, returns the document id
    async sendDocument(imgData) {
      await axios.post('/projects/download_pdf_vue', {
        project_id: this.projectId,
        project_scope_id: this.projectScopeId,
        img_data: imgData,
        site_plan_or_map: this.documentType,
        landscape_or_portrait: this.aspect ? 'Portrait' : 'Landscape',
        seismic: false,
        rmp: false,
        zoning: false,
      })
        .then((response) => {
          if (this.documentType === 'Site Map') {
            this.sitemapdocId = response.data.projectdoc_id;
          } else {
            this.siteplandocId = response.data.projectdoc_id;
          }
          this.locationMarker.position = { lat: parseFloat(this.location.lat), lng: parseFloat(this.location.lng) };
          this.googleMap.setOptions({ disableDefaultUI: false });
          this.showMapWaterMark();
          this.alertDocumentCreated();
          this.save({alert: false});
          this.mapSaved = true;
          this.showLoading = false;
          document.body.style.overflowY = 'scroll';
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, document not created';
          this.showLoading = false;
        });
    },

    // Copy the Site Map data to the Site Plan or vice versa
    async copyMap(mapTypeToCopyTo, mapTypeToCopyFrom) {
      const message = `This will overwrite the current ${mapTypeToCopyTo} with the current ${mapTypeToCopyFrom}. Are you sure you want to continue?`;
      if (!window.confirm(message)) { return; }
      this.showLoading = true;
      await axios.post('/site_maps/copy_map', {
        project_scope_id: this.projectScopeId,
        map_type_to_copy_to: mapTypeToCopyTo,
        map_type_to_copy_from: mapTypeToCopyFrom,
      })
        .then((response) => {
          this.siteMap = response.data.map;
          this.mapObjects = response.data.map_objects;
          this.initialize();
          this.mapSaved = true;
          this.aspect = this.siteMap.orientation;
          this.alertMessage = 'Map Copied';
          this.showLoading = false;
          setTimeout(() => {
            this.alertMessage = '';
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Something went wrong, map not copied';
          this.showLoading = false;
        });
    },

    // Return the link to the site map PDF
    siteMapLink() {
      if (this.sitemapdocId && this.project) {
        const projectNumberString = this.project.project_number_string;
        return `/projectdocs/${this.sitemapdocId}?custom_file_name=${projectNumberString}Figure+1+Site+Location+Map.pdf`;
      }
      return '';
    },

    // Return the link to the site plan PDF
    sitePlanLink() {
      if (this.siteplandocId && this.project) {
        const projectNumberString = this.project.project_number_string;
        return `/projectdocs/${this.siteplandocId}?custom_file_name=${projectNumberString}Figure+1+Site+Location+Plan.pdf`;
      }
      return '';
    },

    // Show the alert message that the document has been created
    alertDocumentCreated() {
      this.alertMessage = `${this.documentType} Created`;
    },

    // Toggle between Site Map and Site Plan
    toggleMapType(mapType) {
      const confirmMessage = 'Any unsaved changes will be lost. Are you sure you want to continue?';
      if (this.mapSaved || window.confirm(confirmMessage)) {
        this.documentType = mapType;
        this.mapObjects = [];
        this.mapDrawings = [];
        this.fetchMap();
      // reset the map type radio buttons
      } else if (mapType === 'Site Map') {
        document.querySelector('.map-type-selector').checked = false;
        document.getElementById('site_plan').checked = true;
      } else if (mapType === 'Site Plan') {
        document.querySelector('.map-type-selector').checked = false;
        document.getElementById('site_map').checked = true;
      }
      // Increment the key to force the full screen drawing manager to remount
      this.fullScreenDrawingManagerKey += 1;
    },

    // Toggle between portrait and landscape
    toggleAspect(aspect) {
      this.aspect = aspect;
      this.siteMap.orientation = aspect;
      this.mapSaved = false;
    },

    // Used for single choice radio buttons
    checkMapType(mapType) {
      if (this.documentType === mapType) {
        return true;
      }
      return false;
    },

    // Show an alert message if the user tries to leave the page with unsaved changes
    setPageExitPrompt() {
      window.onbeforeunload = () => {
        if (!this.mapSaved) {
          return 'You have unsaved changes. Are you sure you want to leave this page?';
        }
        return null;
      };
    },

    // Convert a hex color to an rgba color, used for the text box fill color to maintain the opacity in the PDF
    convertHexToRgb(hex) {
      return this.hexToRgbMap()[hex];
    },

    // Convert an rgb color to a hex color
    convertRgbToHex(rgb) {
      rgb = rgb.replace('rgba', 'rgb');
      return Object.keys(this.hexToRgbMap()).find((hex) => this.hexToRgbMap()[hex] === rgb);
    },

    // Map of hex colors to their corresponding rgb color
    hexToRgbMap() {
      return {
        '#005199': 'rgb(0, 81, 153)',
        '#569900': 'rgb(86, 153, 0)',
        '#9F9EA4': 'rgb(159, 158, 164)',
        '#1F7A8C': 'rgb(31, 122, 140)',
        '#FF3333': 'rgb(255, 0, 0)',
        '#F04C25': 'rgb(240, 76, 37)',
        '#000000': 'rgb(0, 0, 0)',
      };
    }
  },
};
</script>
<style scoped>
  #left_side_pannel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
  }
  #right_side_pannel {
    width: 250px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10px;
    margin-left: 10px;
  }
</style>
