<template>
  <div v-if='mapObjects.length' class='sl-w-100per'>
    <ul style='min-height: 246px' id='map-object-container'>
      <li @click='panToLocation'
          class='map-object-layer'
          :class="{'map-object-active':activeIndex===null}">
        <div class='sl-ml-5px sl-flex-align-items-center'>
          <span class='property-dot'></span>
          <label class='sl-ml-10px'>Property</label>
        </div>
      </li>
      <li v-for='(mapObject, index) in mapObjects'
          @click='setMapObjectActive(index)'
          class='map-object-layer'
          :class="{'sl-border-b-1px':isLastRow(index), 'map-object-active':index===activeIndex}">
        <div class='sl-ml-5px sl-flex-align-items-center'>
          <span class='dot' :style="{'background-color': returnMapObjectDotColor(mapObject)}"></span>
          <label class='sl-ml-10px'>{{ returnMapObjectLabel(mapObject) }}</label>
        </div>
        <deleteIcon @click='deleteMapObject(index)' transform='scale(0.65)' class='figma-icon-delete links-center sl-mr-5px'/>
      </li>
    </ul>
  </div>
  <div v-else class='sl-w-100per'>
    <ul>
      <li @click='panToLocation'
          class='map-object-layer sl-border-b-1px'
          :class="{'map-object-active':activeIndex===null}">
        <div class='sl-ml-5px sl-flex-align-items-center'>
          <span class='property-dot'></span>
          <label class='sl-ml-10px'>Property</label>
        </div>
      </li>
    </ul>
    <ol class='sl-pl-20px sl-pt-10px sl-pb-10px' style='height: 190px'>
      <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'>Center map to the property</li>
      <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'> Draw the property boundary</li>
      <li class='sl-font-size-14px sl-mb-5px' style='color: #696E76'> Create document</li>
    </ol>
  </div>
</template>
<script>
import deleteIcon from '../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';

export default {
  props: {
    mapObjects: {
      type: Array,
      required: true,
    },
    activeIndex: {
      type: Number,
      required: true,
    },
  },
  components: {
    deleteIcon,
  },
  methods: {
    panToLocation() {
      this.$emit('pan-to-location');
    },
    setMapObjectActive(index) {
      this.$emit('set-map-object-active', index);
    },
    deleteMapObject(index) {
      this.$emit('delete-map-object', index);
    },
    // Returns true if the index is the last row in the map objects array
    isLastRow(index) {
      return index === this.mapObjects.length - 1;
    },

    // Return the label for the map object in the right side panel; for text objects, return their content
    returnMapObjectLabel(mapObject) {
      let label = '';
      // For text objects with more than 20 characters
      if (mapObject.type === 'Text' && mapObject?.content?.length > 20) {
        label = `${mapObject.content.substring(0, 20)}...`;
      // For text objects with less than 20 characters
      } else if (mapObject.type === 'Text' && mapObject.content) {
        label = mapObject.content;
      } else {
        label = mapObject.type;
      }
      return label;
    },

    // Return the color of the map object dot in the right side panel
    returnMapObjectDotColor(mapObject) {
      if (mapObject.type === 'Text') {
        return mapObject.fill_color;
      }
      // Every other map object, return the stroke color
      return mapObject.stroke_color;
    },
  },
};
</script>
<style scoped>
.map-object-layer {
  color: #005199;
  font-weight: bold;
  border-top: 1px solid var(--medium-charcoal);
  font-size: 12px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 15px;
}
.map-object-layer:hover {
  color: #005199;
  background-color: #FBFBFB;
}
.map-object-active {
  color: #005199;
  background-color: #E5EEF5 !important;
}
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: #F04C25;
}
.property-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #005199;
}
</style>
