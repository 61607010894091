<template>
  <SpinningModal v-if='showSpinningModal'/>
  <div v-if='dataReady'>
    <h1 class='nd_photos_h1_style sl-mt-10px'>Compliance Policy Versions</h1>
    <AlertMessage :message='alertMessage'/>
    <div class='pl-flex-space-between pl-section-header-bar' style='margin-top: 5px;'>
      <div class='sl-font-size-14px'>Policy Versions</div>
    </div>
    <table class='sl-simple-table-1 sl-text-align-left sl-border-b-2px sl-mb-50px'>
      <thead>
        <tr class='sl-h-42px sl-border-t-0'>
          <th class='sl-w-16px'></th>
          <th class='sl-w-60px'></th>
          <th class='sl-text-align-left sl-w-200px'>Name</th>
          <th class='sl-text-align-left sl-w-225px'>Created At</th>
          <th class='sl-text-align-left sl-w-150px'>Created By</th>
          <th class='sl-text-align-left sl-w-100px'>Policies</th>
          <th class='sl-text-align-left sl-w-100px'>Zip Codes</th>
          <th class='sl-text-align-left sl-w-100px'>Property Types</th>
          <th class='sl-text-align-right'>
            <button class='sl-simple-outline-btn' @click='createNewVersion'>Add Version</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(version, index) in versions' :key='version.id' class='sl-h-45px'>
          <td class='sl-text-align-center'>{{ index + 1 }}</td>
          <td class='sl-flex-align-items-center'>
            <editIcon @click='edit(version)' class='figma-icon sl-mt-5px sl-mr-10px' transform='scale(0.9)'/>
            <deleteIcon @click='deleteVersion(version)' class='figma-icon sl-mt-5px' transform='scale(0.7)'/>
          </td>
          <td><a :href="`/imt_policies?version_id=${version.id}`" class='sl-blue-link sl-bold-text'>{{ version.name }}</a></td>
          <td>{{ version.created_at }}</td>
          <td>{{ version.created_by }}</td>
          <td>{{ version.policies }}</td>
          <td>{{ version.zip_codes }}</td>
          <td>{{ version.property_types }}</td>
          <td class='sl-font-size-12px sl-text-align-right' @click='setCurrentVersion(version)'>
            <span v-if='version.current' class='sl-mr-10px'>Current Version</span>
            <a v-else class='sl-blue-link sl-mr-10px'>Make Current Version</a>
          </td>
        </tr>
      </tbody>
    </table>
    <PolicyVersionModal v-if='showModal'
      :policyVersion='editVersion'
      :saveMessage='saveMessage'
      :importing='importing'
      :errorMessage='errorMessage'
      @update='update'
      @create='create'
      @importFile='importFile'
      @closeModal='closeModal'/>
  </div>
</template>
<script>
import axios from 'axios';
import PolicyVersionModal from './PolicyVersionModal.vue';
import SpinningModal from '../../../components/SpinningModal.vue';
import AlertMessage from '../../../components/AlertMessage.vue';
import editIcon from '../../../../../assets/images/icons/Icon-Edit_Charcoal-Dark-100_20.svg';
import deleteIcon from '../../../../../assets/images/icons/Icon-Archive-Delete_Charcoal-Dark-100_24.svg';

export default {
  components: {
    PolicyVersionModal,
    SpinningModal,
    AlertMessage,
    editIcon,
    deleteIcon,
  },

  data() {
    return {
      versions: [],
      currentVersion: null,
      editVersion: null,
      showSpinningModal: false,
      dataReady: false,
      showModal: false,
      importing: null,
      alertMessage: null,
      saveMessage: null,
      errorMessage: null,
    };
  },

  created() {
    this.fetchPolicyVersions();
  },

  methods: {
    async fetchPolicyVersions() {
      this.showSpinningModal = true;
      await axios.get('/imt_policy_versions/return_policy_versions_json')
        .then((response) => {
          this.versions = response.data.versions;
          this.currentVersion = response.data.current_version;
        })
        .catch(() => {
          this.alertMessage = 'Error Fetching Policy Versions';
        })
        .finally(() => {
          setTimeout(() => {
            this.showSpinningModal = false;
            this.dataReady = true;
          }, 300);
        });
    },

    async setCurrentVersion(version) {
      this.showSpinningModal = true;
      await axios.post('/imt_policy_versions/set_current_version', {
        id: version.id,
      })
        .then((response) => {
          // Update the current version to true, and all other versions to false
          for (let i = 0; i < this.versions.length; i++) {
            this.versions[i].current = this.versions[i].id === version.id;
          }
          this.policies = response.data.policies;
        })
        .catch(() => {
          this.alertMessage = 'Error Setting Current Policy Version';
        })
        .finally(() => {
          setTimeout(() => {
            this.showSpinningModal = false;
          }, 300);
        });
    },

    createNewVersion() {
      this.showModal = true;
      this.editVersion = {
        name: null,
        policies: 0,
        zip_codes: 0,
        property_types: 0,
      };
    },

    edit(version) {
      this.showModal = true;
      this.editVersion = version;
    },

    closeModal() {
      this.showModal = false;
      const index = this.versions.map((version) => version.id).indexOf(this.editVersion.id);
      this.versions[index] = this.editVersion;
      this.editVersion = null;
    },

    async update(versionName) {
      this.importing = 'update';
      await axios.put(`imt_policy_versions/${this.editVersion.id}`, {
        imt_policy_version: {
          name: versionName,
        },
      })
        .then((response) => {
          setTimeout(() => {
            this.editVersion = response.data.version;
            this.saveMessage = 'Policy Version Updated';
            this.errorMessage = null;
            this.importing = null;
            setTimeout(() => {
              this.saveMessage = null;
            }, 3000);
          }, 300);
        })
        .catch(() => {
          this.saveMessage = null;
          this.errorMessage = 'Error Updating Policy Version';
          this.importing = null;
        });
    },

    async create(versionName) {
      this.importing = 'create';
      await axios.post('imt_policy_versions', {
        imt_policy_version: {
          name: versionName,
        },
      })
        .then((response) => {
          setTimeout(() => {
            this.editVersion = response.data.version;
            this.versions.push(response.data.version);
            this.saveMessage = 'Policy Version Created';
            this.errorMessage = null;
            this.importing = null;
            setTimeout(() => {
              this.saveMessage = null;
            }, 3000);
          }, 300);
        }).catch(() => {
          this.saveMessage = null;
          this.errorMessage = 'Error Creating Policy Version';
          this.importing = null;
        });
    },

    async importFile(fileType, displayName, file) {
      this.importing = fileType;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('version_id', this.editVersion.id);
      formData.append('file_type', fileType);
      await axios.post('imt_policy_versions/import_policies', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        this.editVersion[fileType] = response.data.count;
        this.saveMessage = `${displayName} Imported Successfully`;
        this.errorMessage = null;
        this.importing = null;
        const fileInput = document.getElementById(`${fileType}_file`);
        if (fileInput) {
          fileInput.value = null;
        }
        setTimeout(() => {
          this.saveMessage = null;
        }, 3000);
      }).catch(() => {
        this.saveMessage = null;
        this.errorMessage = `Error Importing ${displayName}`;
        this.importing = null;
      });
    },

    async deleteVersion(version) {
      const message = `Are you sure you want to delete the ${version.name} policy version? `
                      + 'This will delete all of the policies, zip codes, and property type specifics '
                      + 'for this version and cannot be undone.';
      if (!window.confirm(message)) { return; }
      this.showSpinningModal = true;
      await axios.delete(`imt_policy_versions/${version.id}`)
        .then(() => {
          this.versions = this.versions.filter((v) => v.id !== version.id);
          this.alertMessage = 'Policy Version Deleted';
          setTimeout(() => {
            this.alertMessage = null;
          }, 3000);
        })
        .catch(() => {
          this.alertMessage = 'Error Deleting Policy Version';
        })
        .finally(() => {
          setTimeout(() => {
            this.showSpinningModal = false;
          }, 300);
        });
    },
  },
};
</script>
